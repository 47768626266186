import { SearchSidebarResult, FilterItemCategoryQueryResult } from 'src/app/models/filter';

export class CategoryItem {
  static mapFromCategoryItemQuery(itemQuery: CategoryItemQueryResultItem): CategoryItem {
    return {
      categoryName: itemQuery.name,
      srcImg: itemQuery.image,
      totalJobs: itemQuery.jobs
    };
  }

  static mapFromSearchSidebarResult(itemQuery: SearchSidebarResult | undefined): CategoryItem | null {
    return (itemQuery?.data.category.length === 1)
      ? {
        categoryName: itemQuery.data.category[0].name,
        totalJobs: itemQuery.data.category[0].jobs,
        srcImg: itemQuery.data.category[0].image
      } : null;
  }

  static getCategoryItemFromFilterItemCategoryQueryResult(itemQuery: FilterItemCategoryQueryResult[], categoryName: string): CategoryItem | null {
    const categoryResult = itemQuery.find((item) => item.name.toLowerCase() === categoryName.toLowerCase());

    return categoryResult ? {
      categoryName: categoryResult.name,
      totalJobs: categoryResult.jobs,
      srcImg: categoryResult.image
    } : null;
  }

  categoryName: string;
  totalJobs: number;
  srcImg?: string;
  cta?: string;
}

export interface CategoryItemQueryResult {
  error: boolean;
  data: CategoryItemQueryResultItem[];
}

export interface CategoryItemQueryResultItem {
  name: string;
  jobs: number;
  image?: string;
}
