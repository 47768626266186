import React from 'react';

export const IconArrowDown = (
  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33">
    <g id="left_nav" data-name="left nav" transform="translate(0 33.004) rotate(-90)">
      <g id="Rectangle_452" data-name="Rectangle 452" transform="translate(0.004)" fill="none" stroke="#fff" strokeWidth="1" opacity="0">
        <rect width="33" height="33" rx="10" stroke="none"/>
        <rect x="0.5" y="0.5" width="32" height="32" rx="9.5" fill="none"/>
      </g>
      <path id="Arrow" d="M8.243,0,0,8.388l8.243,8.1" transform="translate(12.526 8.478)" fill="none" stroke="#b2b2b2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    </g>
  </svg>
);
