import React from 'react';

export class Layout extends React.Component {
  render(): JSX.Element {
    return (
      <>
        <main>
          {this.props.children}
        </main>
      </>
    );
  }
}
