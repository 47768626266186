import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { withStyles } from 'src/app/helpers/styles';
import { SearchResultItem, SearchResultJob } from 'src/app/models/searchResult';
import { withRouter } from 'src/app/helpers/router';
import { JOB_DESCRIPTION, PARAMS } from 'src/app/constants/route';
import { scrollTopIframe } from 'src/app/helpers/iframe';

interface MyOwnProps {
  searchResultItems: SearchResultItem[];
}

type MyProps = MyOwnProps & RouteComponentProps;

@withStyles({
  base: [require('./SearchResult.base.scss')],
  desktop: [require('./SearchResult.desktop.scss')]
})
@withRouter()
class MySearchResult extends React.Component<MyProps> {
  constructor(props: MyProps) {
    super(props);

    this.handleOpenJobDetail = this.handleOpenJobDetail.bind(this);
  }

  render(): JSX.Element {
    const { searchResultItems } = this.props;

    const searchResultItemElements = searchResultItems.map((item, i) => this.renderSearchResultItem(item, i));
    return (
      <div className="search-result__container">
        {searchResultItemElements}
      </div>
    );
  }

  renderSearchResultItem(item: SearchResultItem, index: number): JSX.Element {
    const jobItemElements = item.jobItems.map((item, i) => this.renderJobItem(item, i));
    if ((item.logo) || (item.companyName == "GDP Venture"))
      return (
        <div key={index}>
          <div className="search-result__logo d-flex ai-center">
            {this.renderItemHeader(item)}
          </div>
          {(item.companyName == "GDP Venture")?jobItemElements:<></>}
        </div>
      );
    
    return <></>
  }

  renderItemHeader(item: SearchResultItem): JSX.Element {
    if (item.companyName != "GDP Venture") {
      if (item.logo) {
        let url = item.url
        if(item.url.substring(0,4) != "http") {
          url = '//' + item.url
        }
        return <a href={url} target="_blank"><img src={item.logo} /></a>;
      }
    } else {
      if (item.logo) {
        return <img src={item.logo} />;
      } else {
        return <div className="fs-24 fw-500">{item.companyName}</div>;
      }
    }

    return <></>
    //return <div className="fs-24 fw-500">{item.companyName}</div>;
  }

  renderJobItem(item: SearchResultJob, index: number): JSX.Element {
    return (
      <div key={index} className="search-result__job-item fs-20 jc-between cursor-pointer" onClick={() => this.handleOpenJobDetail(item.id)}>
        <div className="search-result__job-item--company fg-primary mr-24">
          {item.name}
        </div>
        <div className="search-result__job-item--location">
          {item.location}
        </div>
      </div>
    );
  }

  handleOpenJobDetail(jobId: string): void {
    this.props.history.push(JOB_DESCRIPTION.replace(PARAMS.ID, jobId));
    scrollTopIframe();
  }
}

export const SearchResult = MySearchResult as React.ComponentClass<MyOwnProps>;
