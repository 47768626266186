import React from 'react';
import { IntlProvider } from 'react-intl';

import { LOCALE_EN, MESSAGES } from 'src/app/constants/locale';

export class LocaleProvider extends React.Component {
  render(): JSX.Element {
    return (
      <IntlProvider locale={LOCALE_EN} messages={MESSAGES[LOCALE_EN]}>
        {this.props.children}
      </IntlProvider>
    );
  }
}
