import classnames from 'classnames';
import React from 'react';

import { FilterItem } from 'src/app/models/filter';
import { ButtonIcon } from 'src/app/components/Button/ButtonIcon';
import { FilterCheckbox } from 'src/app/components/FilterCheckbox/FilterCheckbox';
import { IconCloseSmall } from 'src/app/components/Icon/Icon';
import { withInjectedStyles } from 'src/app/helpers/styles';
import { Button } from 'src/app/components/Button/Button';
import { InjectedStylesProps } from 'src/app/models/styles';

type MyInjectedProps = InjectedStylesProps;

interface MyOwnProps {
  title: string;
  filterItems: FilterItem[];
  onCloseIconClick: () => void;
  onApplyFilters: (filterItems: FilterItem[]) => void;
  onResetFilters: () => void;
}

type MyProps = MyOwnProps & MyInjectedProps

interface MyState {
  currentFilterItems: FilterItem[];
}

@withInjectedStyles({
  base: [require('./FilterPopup.base.scss')],
  desktop: [require('./FilterPopup.desktop.scss')]
})
class MyFilterPopup extends React.Component<MyProps, MyState> {
  private refComponent: React.RefObject<HTMLDivElement>;

  constructor(props: MyProps) {
    super(props);
    this.refComponent = React.createRef();

    this.state = {
      currentFilterItems: props.filterItems
    };

    this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
    this.handleApply = this.handleApply.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  render(): JSX.Element {
    const { title } = this.props;

    return (
      <div className="d-flex fld-column">
        <div className="d-flex jc-between ai-center mb-24">
          <div className="">
            {title}
          </div>
          <ButtonIcon
            className="filter-popup__close-icon p-8 fs-12"
            onClick={this.props.onCloseIconClick}
            icon={IconCloseSmall}
            modifier="clear" />
        </div>
        {this.renderFilterItems()}
        <div className="d-flex pt-30 filter-popup__button-container">
          <Button
            className={classnames('button--format-32 tt-uppercase fs-14 b-1 fg-white filter-popup__button mr-16')}
            modifier="primary-light"
            type="button"
            onClick={this.handleApply}
          >
            APPLY
          </Button>
          <Button
            className={classnames('button--format-32 tt-uppercase fs-14 b-1 fg-primary-light filter-popup__button')}
            modifier="white"
            type="button"
            onClick={this.handleReset}
          >
            RESET
          </Button>
        </div>
      </div>
    );
  }

  renderFilterItems(): JSX.Element {
    const { currentFilterItems } = this.state;

    const filterItemsElements = currentFilterItems.map((item, i) => {
      const textDisplay = item.totalItems > 0 ? `${item.name} (${item.totalItems})` : item.name;
      const onChange = item.totalItems > 0 ? this.handleCheckboxClick : () => {};
      return (
        <div
          key={i}
          className={classnames('mb-12 d-flex ai-center mr-18', item.totalItems === 0 ? 'filter-popup__item-disabled' : 'cursor-pointer')}
          onClick={() => onChange(item.name)} >
          <div className="mr-8">
            <FilterCheckbox
              name={item.name}
              isChecked={item.isSelected}
            />
          </div>
          <div>
            {textDisplay}
          </div>
        </div>
      );
    });

    return (
      <div className="d-block d@md-flex fld-column flw-wrap filter-popup__filter-items flex-1" ref={this.refComponent}>
        {filterItemsElements}
      </div>
    );
  }

  handleCheckboxClick(name: string): void {
    const { currentFilterItems } = this.state;

    const newFilterItems = currentFilterItems.map((item) => {
      if (item.name === name) {
        return {
          ...item,
          isSelected: !item.isSelected
        };
      }
      return item;
    });

    this.setState({
      currentFilterItems: newFilterItems
    });
  }

  handleApply(): void {
    const { onApplyFilters } = this.props;
    const { currentFilterItems } = this.state;

    onApplyFilters(currentFilterItems);
  }

  handleReset(): void {
    const { currentFilterItems } = this.state;

    const newFilterItems = currentFilterItems.map((item) => {
      return {
        ...item,
        isSelected: false
      };
    });

    this.setState({
      currentFilterItems: newFilterItems
    });
  }
}

export const FilterPopup = MyFilterPopup as React.ComponentClass<MyOwnProps>;
