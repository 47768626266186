export enum PARAMS {
  ID = ':id'
}

export const HOME = '/';
export const ABOUT_US = '/about';
export const SEARCH = '/search';
export const JOB_DESCRIPTION = '/job-description/' + PARAMS.ID;
export const JOB_APPLICATION = '/job-application/' + PARAMS.ID;

export const queryString = {
  location: 'location',
  company: 'company',
  category: 'category',
  keyword: 'keyword'
};
