import React from 'react';

import { Status } from '@gdp/react-app-plugin-router';

export function NotFound(): JSX.Element {
  return (
    <Status code={404}>
      <h1>Not Found!</h1>
    </Status>
  );
}
