export function resizeIframe(): void {
  const height = document.body.scrollHeight;
  const message = {
    'height': height + 'px',
    'div_id': 'gdpvcareer',
    'class': 'gdpvcareer'
  };
  window.parent.postMessage(JSON.stringify(message), '*');
}

export function scrollTopIframe(): void {
  const height = 100;
  const message = {
    'height': height + 'px',
    'div_id': 'gdpvcareer',
    'class': 'gdpvcareer'
  };
  window.parent.postMessage(JSON.stringify(message), '*');
  setTimeout(resizeIframe, 200);
}
