import { History } from 'history';
import React from 'react';
import { RouteComponentProps, withRouter as $withRouter } from 'react-router-dom';

export function redirectTo(history: History, path: string, state: any) {
  return () => history.push(path, state);
}

export function withRouter(): <C>(WrappedComponent: C) => C {
  function wrapperComponent<C>(WrappedComponent: C): C;
  function wrapperComponent<P extends RouteComponentProps>(WrappedComponent: React.ComponentType<P>): React.ComponentClass<Pick<P, Exclude<keyof P, keyof RouteComponentProps>>> {
    return $withRouter<any, any>(WrappedComponent);
  }

  return wrapperComponent;
}
