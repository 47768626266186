import React from 'react';
import { FormattedMessage, WrappedComponentProps as InjectedIntlProps } from 'react-intl';

import { withIntl } from 'src/app/helpers/locale';
import { withInjectedStyles } from 'src/app/helpers/styles';

type MyInjectedProps = InjectedIntlProps;

type MyProps = MyInjectedProps;

@withIntl()
@withInjectedStyles(require('./PopupSubmitSuccess.scss'))
class MyClassPopupSubmitSuccess extends React.Component<MyProps> {
  render(): JSX.Element {
    return (
      <div onClick={this.onPopupClick} className="px-20 py-16 pb-34 bg-white radius-small position-absolute popup-body ta-center d-flex">
        <div className="m-auto flex-1">
          <div className="done-icon mt-24"></div>
          <div className="mt-24 fs-20 fw-500 fg-black ta-center">
            <FormattedMessage id="job.application.content.submitSuccess" />
          </div>
        </div>
      </div>
    );
  }

  private onPopupClick(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
  }
}

export const PopupSubmitSuccess = MyClassPopupSubmitSuccess as React.ComponentClass;
