import React from 'react';

export const IconFilter = (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <g id="filter_button" data-name="filter button" transform="translate(-364 -120)">
      <g id="Icon_feather-filter" data-name="Icon feather-filter" transform="translate(364 119.5)" fill="none" strokeLinecap="round" strokeLinejoin="round">
        <path d="M26.577,4.5H3l9.431,11.152v7.71l4.715,2.358V15.652Z" stroke="none"/>
        <path d="M 7.310611724853516 6.500003814697266 L 13.95778369903564 14.36028289794922 C 14.26311302185059 14.72133350372314 14.43064308166504 15.17888355255127 14.43064308166504 15.6517333984375 L 14.43064308166504 22.12521743774414 L 15.14596366882324 22.48287773132324 L 15.14596366882324 15.6517333984375 C 15.14596366882324 15.17888355255127 15.3134937286377 14.72133350372314 15.61882305145264 14.36028289794922 L 22.26599502563477 6.500003814697266 L 7.310611724853516 6.500003814697266 M 3.000003814697266 4.500003814697266 L 26.57660293579102 4.500003814697266 L 17.14596366882324 15.6517333984375 L 17.14596366882324 25.71894264221191 L 12.43064308166504 23.36128234863281 L 12.43064308166504 15.6517333984375 L 3.000003814697266 4.500003814697266 Z" stroke="none" fill="#004b80"/>
      </g>
      <rect id="Box" width="30" height="30" transform="translate(364 120)" fill="none"/>
    </g>
  </svg>
);
