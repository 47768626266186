import React from 'react';
import { Helmet } from 'react-helmet-async';
import { RouteComponentProps } from 'react-router-dom';

import { HomeHeader } from 'src/app/components/Home/HomeHeader';
import { HomeContent } from 'src/app/components/Home/HomeContent';
import { Loader } from 'src/app/components/Loader/Loader';
import { SEARCH, queryString } from 'src/app/constants/route';
import { withQuery } from 'src/app/helpers/query';
import { CategoryItem, CategoryItemQueryResult } from 'src/app/models/category';
import { QueryInjectedProps } from 'src/app/models/query';

interface InjectedProps {
  categoryListData: QueryInjectedProps<CategoryItemQueryResult>;
}

type MyProps = RouteComponentProps & InjectedProps;

@withQuery('/category/list', {
  name: 'categoryListData'
})
export class Home extends React.Component<MyProps> {
  constructor(props: MyProps) {
    super(props);

    this.handleOpenSearchCategory = this.handleOpenSearchCategory.bind(this);
    this.handleOpenSearchPage = this.handleOpenSearchPage.bind(this);
  }

  render(): JSX.Element {
    const { categoryListData: { resultData, isLoading } } = this.props;

    if (isLoading && !resultData) {
      return <Loader />;
    } else if (resultData) {
      return (
        <>
          <Helmet>
            <title>GDPV Career Page</title>
          </Helmet>
          <div className="bg-gray-lighter">
            <HomeHeader openSearchPage={this.handleOpenSearchPage} />
            {this.renderContent()}
          </div>
        </>
      );
    } else {
      return <div>ERROR</div>;
    }
  }

  renderContent(): JSX.Element | null {
    const { categoryListData: { resultData } } = this.props;
    if (resultData) {
      let categoryItems : CategoryItem[] = [];
      let lastCategoryItem;
      categoryItems = resultData.data.reduce((result, value, index) => {
        if (value.name.toLowerCase() === "others") {
          lastCategoryItem = CategoryItem.mapFromCategoryItemQuery(value);
        } else {
          result.push(CategoryItem.mapFromCategoryItemQuery(value));
        }
        return result;
      }, categoryItems);

      if (lastCategoryItem) {
        categoryItems.push(lastCategoryItem);
      }

      return (
        <div className="container container-fluid">
          <HomeContent
            categoryItems={categoryItems}
            openSearchCategory={this.handleOpenSearchCategory} />
        </div>
      );
    } else {
      return null;
    }
  }

  handleOpenSearchCategory(categoryName: string): void {
    this.props.history.push({
      pathname: SEARCH,
      search: `?${queryString.category}=${categoryName.toLowerCase()}`
    });
  }

  handleOpenSearchPage(query: string): void {
    this.props.history.push({
      pathname: SEARCH,
      search: `?${queryString.keyword}=${query.toLowerCase()}`
    });
  }
}
