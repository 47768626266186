export interface QueryInjectedProps<T> {
  resultData?: T;
  isLoading: boolean;
  error: boolean;
  setParam: <D>(param: {
    queryString?: string;
    reqData?: D;
  }) => void;
  fetch: <D>(param?: {
    queryString?: string;
    reqData?: D;
  }) => Promise<void>;
}

export enum HTTP_METHODS {
  GET = 'get',
  POST = 'post'
}
