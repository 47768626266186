import React from 'react';
import { FormattedMessage, WrappedComponentProps as InjectedIntlProps } from 'react-intl';

import { withIntl } from 'src/app/helpers/locale';
import { withStyles } from 'src/app/helpers/styles';
import { AdditionalQuestions } from 'src/app/models/job';
import { JobApplicationForm } from 'src/app/components/JobApplication/JobApplicationForm';
import { PopupContainer } from 'src/app/components/PopupShare/PopupContainer';
import { PopupSubmitSuccess } from 'src/app/components/PopupShare/PopupSubmitSuccess';
import { withConfig } from 'src/app/helpers/config';
import { InjectedConfigProps } from 'src/app/models/config';

type MyInjectedProps = InjectedIntlProps;

interface MyOwnProps {
  jobId: string;
  securityToken: string;
  questions: AdditionalQuestions[];
  onSuccessSubmit: () => void;
}

type MyProps = MyOwnProps & MyInjectedProps & InjectedConfigProps;

interface MyState {
  isPopupActive: boolean;
}

@withConfig()
@withIntl()
@withStyles({
  base: [require('./JobApplicationContent.base.scss')],
  desktop: [require('./JobApplicationContent.desktop.scss')]
})

class MyClassJobApplicationContent extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);

    this.state = {
      isPopupActive: false
    };
    this.toggleSharePopup = this.toggleSharePopup.bind(this);
  }

  render(): JSX.Element {
    return (
      <div className="d-flex flw-wrap">
        <div className="mt-24 mt@md-20 fs-14 d-flex d@md-block jc-between player-detail__button">
          {this.state.isPopupActive && this.renderedPopupSubmitResult}
        </div>
        <div className="fs-24 w-100">
          <div className="my-30">
            <FormattedMessage id="job.application.content.submityourapplication" />
          </div>
          <div className="my-30">
            <JobApplicationForm
              jobId={this.props.jobId}
              securityToken={this.props.securityToken}
              additionalQuestions={this.props.questions}
              apiUrl={this.props.config.app.apiUrl}
              captchaKey={this.props.config.app.captchaKey}
              showSuccessPopup={this.toggleSharePopup}
            />
          </div>
        </div>
      </div>
    );
  }

  private get renderedPopupSubmitResult(): JSX.Element {
    return (
      <PopupContainer onClick={this.toggleSharePopup}>
        <PopupSubmitSuccess/>
      </PopupContainer>
    );
  }

  private toggleSharePopup(): void {
    const popupActive = this.state.isPopupActive;
    this.setState({
      isPopupActive: !popupActive
    });
    if (popupActive) {
      this.props.onSuccessSubmit();
    }
  }
}

export const JobApplicationContent = MyClassJobApplicationContent as React.ComponentClass<MyOwnProps>;
