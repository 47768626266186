import React from 'react';
import axios from 'axios';
import qs from 'qs';

import { withConfig } from 'src/app/helpers/config';
import { InjectedConfigProps } from 'src/app/models/config';
import { HTTP_METHODS } from 'src/app/models/query';
import { PopupContainer } from '../components/PopupShare/PopupContainer';
import { PopupError } from '../components/PopupError/PopupError';

interface OperationOptions {
  name?: string;
  contentType?: string;
  method?: HTTP_METHODS;
  data?: any;
}

export function withQuery<T>(endpoint: string, operationOptions?: OperationOptions): <C>(WrappedComponent: C) => C {
  function wrappedComponent<C>(WrappedComponent: C): C;
  function wrappedComponent<P extends T>(WrappedComponent: React.ComponentType<P>): React.ComponentClass<Pick<P, Exclude<keyof P, keyof T>>> {
    type MyProps = P & InjectedConfigProps;

    interface MyState {
      isLoading: boolean;
      error: boolean;
      data?: T;
    }

    @withConfig()
    class MyClassWithInjectedQuery extends React.Component<MyProps, MyState> {
      queryString = '';
      reqData: any;

      constructor(props: MyProps) {
        super(props);

        this.state = {
          isLoading: true,
          error: false
        };

        this.setParam = this.setParam.bind(this);
        this.fetch = this.fetch.bind(this);
      }

      componentDidMount(): void {
        this.fetch();
      }

      render(): JSX.Element {
        const { data, isLoading, error } = this.state;

        let name = 'data';

        if (operationOptions && operationOptions.name) {
          name = operationOptions.name;
        }

        const childProps = {
          [name]: {
            resultData: data,
            isLoading: isLoading,
            setParam: this.setParam,
            fetch: this.fetch,
            error: error
          }
        };
        return (
          <>
            {this.renderPopupError()}
            <WrappedComponent {...this.props} {...childProps}/>
          </>
        );
      }

      renderPopupError(): JSX.Element | null {
        if (!this.state.error) {
          return null;
        }

        return (
          <PopupContainer>
            <PopupError />
          </PopupContainer>
        );
      }

      setParam<D>(param: {
        queryString?: string;
        reqData?: D;
      }): void {
        if (param.queryString) {
          this.queryString = param.queryString;
        }
        if (param.reqData) {
          this.reqData = param.reqData;
        }
      }

      fetch<D>(param?: {
        queryString?: string;
        reqData?: D;
      }): Promise<void> {
        const { apiUrl } = this.props.config.app;

        this.setState({ isLoading: true });

        if (param) {
          this.setParam(param);
        }

        if (operationOptions?.method === HTTP_METHODS.POST) {
          const reqData = operationOptions.contentType === 'application/x-www-form-urlencoded' ? qs.stringify(this.reqData) : this.reqData;
          return axios({
            method: 'post',
            url: `${apiUrl}${endpoint}/${this.queryString}`,
            data: reqData,
            headers: {
              'content-type': operationOptions.contentType
            }
          })
            .then(response => {
              this.handleResponse(response);
            }).catch(() => {
              this.handleError();
            });
        } else {
          return axios({ method: 'get', url: `${apiUrl}${endpoint}/${this.queryString}` })
            .then(response => {
              this.handleResponse(response);
            }).catch(() => {
              this.handleError();
            });
        }
      }

      handleResponse(response: any): void {
        if (response.data.error) {
          this.setState({
            error: true,
            isLoading: false
          });
        } else {
          this.setState({
            data: response.data,
            isLoading: false,
            error: false
          });
        }
      }

      handleError(): void {
        this.setState({
          error: true,
          isLoading: false
        });
      }
    }

    return MyClassWithInjectedQuery;
  }

  return wrappedComponent;
}
