import classnames from 'classnames';
import React from 'react';

import { withStyles } from 'src/app/helpers/styles';

interface MyProps {
  name: string;
  isChecked: boolean;
}

@withStyles(require('./FilterCheckbox.scss'))
export class FilterCheckbox extends React.Component<MyProps> {
  render(): JSX.Element {
    const { isChecked } = this.props;

    return (
      <div className={classnames('filter-checkbox', isChecked && 'filter-checkbox__is-checked')}>
        <div className={classnames('filter-checkbox__checkmark', !isChecked && 'd-none')}></div>
      </div>
    );
  }
}
