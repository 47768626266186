import { withStyles } from '@gdp/react-app-plugin-sass/lib/withStyles';
import React from 'react';

import { AppStyles } from 'src/app/models/styles';

interface MyProps<P> {
  isMobile: boolean;
  originalComponent: React.ComponentType<P>;
  originalProps: P;
  styles: AppStyles;
}

export class WrappedStyles<P> extends React.Component<MyProps<P>> {
  private WrappedComponent: React.ComponentClass;

  constructor(props: MyProps<P>) {
    super(props);

    const styles: NodeRequire[] = [...props.styles.base];
    if (!props.isMobile) {
      styles.push(...props.styles.desktop);
    }
    this.WrappedComponent = withStyles(...styles)(props.originalComponent);
  }

  render(): JSX.Element {
    return <this.WrappedComponent {...this.props.originalProps} />;
  }
}
