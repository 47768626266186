// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../images/icon-done.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".popup-body{width:100%;max-width:400px;bottom:100px;right:50%;transform:translate(50%);border-radius:10px}.border-button{border-radius:50%;border:1px solid #004b80}.done-icon{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-position:50%;background-size:cover;width:100px;height:100px;margin-left:auto;margin-right:auto}", ""]);
// Exports
module.exports = exports;
