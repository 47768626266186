import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { QueryInjectedProps, HTTP_METHODS } from 'src/app/models/query';
import { MoreJobsQueryResult, MoreJobsParams } from 'src/app/models/job';
import { withQuery } from 'src/app/helpers/query';
import { Loader } from 'src/app/components/Loader/Loader';
import { withRouter } from 'src/app/helpers/router';
import { JOB_DESCRIPTION, PARAMS } from 'src/app/constants/route';
import { shallowEqual } from 'src/app/helpers/object';
import { scrollTopIframe } from 'src/app/helpers/iframe';

interface InjectedProps extends RouteComponentProps{
  moreJobsdata: QueryInjectedProps<MoreJobsQueryResult>;
}

interface MyOwnProps {
  params: MoreJobsParams;
}

type MyProps = MyOwnProps & InjectedProps;

@withQuery('/jobs/more', {
  name: 'moreJobsdata',
  contentType: 'application/x-www-form-urlencoded',
  method: HTTP_METHODS.POST
})
@withRouter()
class MyMoreJobs extends React.Component<MyProps> {
  constructor(props: MyProps) {
    super(props);

    props.moreJobsdata.setParam<MoreJobsParams>({
      reqData: props.params
    });

    this.handleOpenSearchCategory = this.handleOpenSearchCategory.bind(this);
  }

  componentDidUpdate(prevProps: MyProps): void {
    if (!shallowEqual(prevProps.params, this.props.params)) {
      this.props.moreJobsdata.fetch<MoreJobsParams>({
        reqData: this.props.params
      });
    }
  }

  render(): JSX.Element | null {
    const { moreJobsdata: { resultData, isLoading } } = this.props;
    if (isLoading) {
      return <Loader />;
    } else if (resultData && resultData.data && resultData.data.length > 0) {
      return (
        <>
          <div className="fw-700 fw@md-normal"><FormattedMessage id="job.description.header.morejobs" /></div>
          <div className="mt-10">
            {resultData.data?.map((value, i) => (
              <div key={i} className="mb-10">
                <div className="fw-500 fg-primary cursor-pointer" onClick={() => this.handleOpenSearchCategory(value.id)}>{value.title}</div>
                <div className="fg-gray fs-16">
                  {value.organization} - {value.location}
                </div>
              </div>
            ))}
          </div>
        </>
      );
    } else {
      return null;
    }
  }

  handleOpenSearchCategory(jobId: string | undefined): void {
    if (jobId) {
      scrollTopIframe();
      const jobDetailLink = JOB_DESCRIPTION.replace(PARAMS.ID, jobId);
      this.props.history.push(jobDetailLink);
    }
  }
}

export const MoreJobs = MyMoreJobs as React.ComponentClass<MyOwnProps>;
