import classnames from 'classnames';
import React from 'react';

import { ButtonIcon } from 'src/app/components/Button/ButtonIcon';
import { IconArrowDown } from 'src/app/components/Icon/IconArrowDown';
import { IconArrowUp } from 'src/app/components/Icon/IconArrowUp';
import { withStyles } from 'src/app/helpers/styles';
import { FilterItem } from 'src/app/models/filter';
import { FilterPopup } from 'src/app/components/FilterDropdown/FilterPopup';
import { FilterCheckbox } from 'src/app/components/FilterCheckbox/FilterCheckbox';
import { scrollTopIframe } from 'src/app/helpers/iframe';

interface MyProps {
  title: string;
  filterItems: FilterItem[];
  allFilterItems: FilterItem[];
  onCheckBoxChange: (name: string, directApply: boolean) => void;
  onApplyFilterClick: (filterItems: FilterItem[]) => void;
  onResetFilterClick: () => void;
}

interface MyState {
  isOpen: boolean;
  isPopupOpened: boolean;
}

@withStyles({
  base: [require('./FilterDropdown.base.scss')],
  desktop: [require('./FilterDropdown.desktop.scss')]
})
export class FilterDropdown extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);

    this.state = {
      isOpen: true,
      isPopupOpened: false
    };

    this.handleOpenToggle = this.handleOpenToggle.bind(this);
    this.handleTogglePopup = this.handleTogglePopup.bind(this);
    this.handleApplyFilter = this.handleApplyFilter.bind(this);
  }

  render(): JSX.Element | null {
    const { title } = this.props;
    const { isOpen } = this.state;

    return (
      <div className="filter-dropdown__container w-100 px-12 py-10 fs-14 fg-gray">
        <div className="d-flex ai-center">
          <div className="flex-1">
            {title}
          </div>
          <ButtonIcon className="fs-12 ls@md-1-5" icon={isOpen ? IconArrowUp : IconArrowDown} onClick={this.handleOpenToggle} modifier="clear" />
        </div>
        {this.renderItems()}
      </div>
    );
  }

  renderItems(): JSX.Element | null {
    const { filterItems, onCheckBoxChange, title } = this.props;
    const { isOpen } = this.state;

    if (!isOpen) {
      return null;
    }

    const filterItemsElements = filterItems.map((item, i) => {
      const textDisplay = item.totalItems > 0 ? `${item.name} (${item.totalItems})` : item.name;
      const onChange = item.totalItems > 0 ? (e: any) => onCheckBoxChange(e, true) : () => {};
      return (
        <div
          key={i}
          className={classnames('mb-12 d-flex ai-center', item.totalItems === 0 ? 'filter-dropdown__item-disabled' : 'cursor-pointer')}
          onClick={() => onChange(item.name)}>
          <div className="mr-8">
            <FilterCheckbox
              name={item.name}
              isChecked={item.isSelected}
            />
          </div>
          <div className={item.totalItems > 0 ? '' : 'fg-gray'}>
            {textDisplay}
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="mt-20 mb-10">
          {filterItemsElements}
          <div className="fg-primary-light cursor-pointer" onClick={this.handleTogglePopup}>
            {`SEE ALL ${title}`}
          </div>
        </div>
        {this.renderPopup()}
      </>
    );
  }

  renderPopup(): JSX.Element | null {
    if (!this.state.isPopupOpened) {
      return null;
    }

    return (
      <div className="position-relative">
        <div className="filter-dropdown__overlay--background"></div>
        <div className="filter-dropdown__overlay--container">
          <FilterPopup
            title={this.props.title}
            filterItems={this.props.allFilterItems}
            onCloseIconClick={this.handleTogglePopup}
            onApplyFilters={this.handleApplyFilter}
            onResetFilters={this.props.onResetFilterClick}
          />
        </div>
      </div>
    );
  }

  private handleApplyFilter(filterItems: FilterItem[]): void {
    this.handleTogglePopup();
    this.props.onApplyFilterClick(filterItems);
  }

  private handleOpenToggle(): void {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  private handleTogglePopup(): void {
    if (!this.state.isPopupOpened) {
      scrollTopIframe();
    }
    this.setState({
      isPopupOpened: !this.state.isPopupOpened
    });
  }
}
