/* eslint-disable getter-return */
import React from 'react';

interface MyProps {
  onLoadMore: VoidFunction;
  scrollComponent: HTMLDivElement;
  threshold?: number;
}

function getScrollTop(): number {
  const containerElement = document.documentElement || document.body;
  return ((typeof window !== 'undefined') && window.pageYOffset) || containerElement.scrollTop;
}

function cumulativeOffset(target: HTMLElement): { top: number; left: number; } {
  let top = 0;
  let left = 0;
  let element: HTMLElement | null = target;
  do {
    top += element.offsetTop || 0;
    left += element.offsetLeft || 0;
    element = element.offsetParent as HTMLElement | null;
  } while (element);

  return { top, left };
}

export class InfiniteScrollListener extends React.Component<MyProps> {
  constructor(props: MyProps) {
    super(props);

    this.scrollListener = this.scrollListener.bind(this);
  }

  componentDidMount(): void {
    this.handleHasMoreForComponentMounted();
  }

  componentDidUpdate(): void {
    this.handleHasMoreForComponentUpdated();
  }

  componentWillUnmount(): void {
    this.detachScrollListener();
  }

  render(): null {
    return null;
  }

  private get eventListenerOptions(): AddEventListenerOptions {
    try {
      let supportsPassive = false;
      const opts = Object.defineProperty({}, 'passive', {
        get: () => {
          supportsPassive = true;
        }
      });
      window.addEventListener('testPassive', (e) => e, opts);
      window.removeEventListener('testPassive', (e) => e, opts);
      return { passive: supportsPassive };
    } catch (e) {
      return { passive: false };
    }
  }

  private handleHasMoreForComponentMounted(): void {
    this.attachScrollListener();
    this.scrollListener();
  }

  private handleHasMoreForComponentUpdated(): void {
    this.attachScrollListener();
  }

  private attachScrollListener(): void {
    window.addEventListener('scroll', this.scrollListener, this.eventListenerOptions);
    window.addEventListener('resize', this.scrollListener, this.eventListenerOptions);
  }

  private detachScrollListener(): void {
    window.removeEventListener('scroll', this.scrollListener, this.eventListenerOptions);
    window.removeEventListener('resize', this.scrollListener, this.eventListenerOptions);
  }

  private scrollListener(): void {
    const { onLoadMore, scrollComponent } = this.props;
    const top = cumulativeOffset(scrollComponent).top;
    const scrollTop = getScrollTop();
    const offset = top + scrollComponent.offsetHeight - scrollTop;
    const threshold = this.props.threshold || 0;
    if (offset <= (window.innerHeight + threshold) && scrollComponent.offsetParent !== null) {
      this.detachScrollListener();

      onLoadMore();
    }
  }
}
