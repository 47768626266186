import React from 'react';
import { WrappedComponentProps as InjectedIntlProps, FormattedMessage } from 'react-intl';

import { withIntl } from 'src/app/helpers/locale';
import { withStyles } from 'src/app/helpers/styles';
import { FilterDropdown } from 'src/app/components/FilterDropdown/FilterDropdown';
import { IconSearch } from 'src/app/components/Icon/IconSearch';
import { ButtonIcon } from 'src/app/components/Button/ButtonIcon';
import { FilterItem, FILTER_TYPE } from 'src/app/models/filter';
import { IconCloseSmall } from 'src/app/components/Icon/Icon';

type MyInjectedProps = InjectedIntlProps;

interface MyOwnProps {
  companyFilterItems: FilterItem[];
  locationFilterItems: FilterItem[];
  categoryFilterItems: FilterItem[];
  allCompanyFilterItems: FilterItem[];
  allLocationFilterItems: FilterItem[];
  allCategoryFilterItems: FilterItem[];
  onCompanyCheckboxChange: (name: string, directApply: boolean) => void;
  onLocationCheckboxChange: (name: string, directApply: boolean) => void;
  onCategoryCheckboxChange: (name: string, directApply: boolean) => void;
  searchValue: string;
  onSubmitSearch: (event: React.FormEvent<HTMLFormElement>) => void;
  onApplyFilterClick: (filterItems: FilterItem[], filterType: FILTER_TYPE) => void;
  onQuerySearchChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onResetFilterClick: () => void;
  onCloseIconClick: () => void;
}

type MyProps = MyOwnProps & MyInjectedProps;

@withIntl()
@withStyles(require('./SearchSidebar.scss'))
class MyClassSearchSidebar extends React.Component<MyProps> {
  render(): JSX.Element {
    const {
      intl: { formatMessage },
      // companyFilterItems,
      // locationFilterItems,
      categoryFilterItems,
      // allCompanyFilterItems,
      allCategoryFilterItems,
      // allLocationFilterItems,
      // onCompanyCheckboxChange,
      // onLocationCheckboxChange,
      onCategoryCheckboxChange,
      onQuerySearchChange,
      onSubmitSearch,
      searchValue,
      onApplyFilterClick,
      onResetFilterClick,
      onCloseIconClick
    } = this.props;

    return (
      <div>
        <div className="mb-20 mb@md-48">
          <div className="fs-32 fw-500 d-none d@md-flex mb-18 search-sidebar__title">
            <FormattedMessage id="search.sidebar.header.title" />
          </div>
          <div className="fs-18 d-none d@md-flex search-sidebar__subtitle">
            <FormattedMessage id="search.sidebar.header.subtitle" />
          </div>
          <div className="fs-20 d-flex d@md-none fg-gray-dark jc-between">
            <FormattedMessage id="search.sidebar.header.filters" />
            <ButtonIcon
              className="search-sidebar__close-icon p-8 fs-12"
              onClick={onCloseIconClick}
              icon={IconCloseSmall}
              modifier="clear" />
          </div>
        </div>
        <div className="mb-34">
          <form className="search-sidebar__search-form w-100 px-12 py-12 fs-14 d-none d@md-flex mb-20" onSubmit={onSubmitSearch}>
            <input
              onChange={onQuerySearchChange}
              className="search-sidebar__search-input flex-1 b-0 bg-transparent outline-0 "
              type="text"
              value={searchValue}
              placeholder={formatMessage({ id: 'home.header.search' })}>
            </input>
            <ButtonIcon className="fs-12 ls@md-1-5" icon={IconSearch} modifier="clear" />
          </form>
          <div className="fg-primary-light mb-20 cursor-pointer" onClick={onResetFilterClick}>
            <FormattedMessage id="search.sidebar.reset.filters" />
          </div>
          <div className="mb-10">
            <FilterDropdown
              title="CATEGORY"
              filterItems={categoryFilterItems}
              allFilterItems={allCategoryFilterItems}
              onCheckBoxChange={onCategoryCheckboxChange}
              onApplyFilterClick={(filterItems) => onApplyFilterClick(filterItems, FILTER_TYPE.CATEGORY)}
              onResetFilterClick={onResetFilterClick}
            />
          </div>
          {/* <div className="mb-10">
            <FilterDropdown
              title="LOCATION"
              filterItems={locationFilterItems}
              allFilterItems={allLocationFilterItems}
              onCheckBoxChange={onLocationCheckboxChange}
              onApplyFilterClick={(filterItems) => onApplyFilterClick(filterItems, FILTER_TYPE.LOCATION)}
              onResetFilterClick={onResetFilterClick}
            />
          </div> */}
          {/* <div className="mb-10">
            <FilterDropdown
              title="COMPANY"
              filterItems={companyFilterItems}
              allFilterItems={allCompanyFilterItems}
              onCheckBoxChange={onCompanyCheckboxChange}
              onApplyFilterClick={(filterItems) => onApplyFilterClick(filterItems, FILTER_TYPE.COMPANY)}
              onResetFilterClick={onResetFilterClick}
            />
          </div> */}
        </div>
      </div>
    );
  }
}

export const SearchSidebar = MyClassSearchSidebar as React.ComponentClass<MyOwnProps>;
