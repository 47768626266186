import React from 'react';

export const IconFacebook = (
  <svg aria-label="Icon Facebook" width="1em" height="1em" viewBox="0 0 40 40">
    <path d="M39.764 37.634a2.121 2.121 0 01-2.13 2.13H27.456V24.142h5.208l.71-6.154h-5.918V14.2c0-1.657.474-2.84 3.078-2.84h3.314V5.916c-.473 0-2.367-.237-4.734-.237-4.734 0-7.811 2.84-7.811 8.047v4.5h-5.207v6.154h5.207V40H2.131a2.121 2.121 0 01-2.13-2.13V2.13A2.121 2.121 0 012.131 0h35.74a2.121 2.121 0 012.13 2.13v35.5"/>
  </svg>
);

export const IconMail = (
  <svg aria-label="Icon Mail" width="1em" height="1em" viewBox="0 0 41 28.384">
    <path d="M40.664 3.558l-10.6 10.768a.19.19 0 000 .275l7.417 7.883a1.272 1.272 0 010 1.808 1.288 1.288 0 01-1.813 0l-7.392-7.85a.2.2 0 00-.286 0l-1.8 1.827a7.948 7.948 0 01-5.665 2.374 8.107 8.107 0 01-5.775-2.446l-1.735-1.759a.2.2 0 00-.286 0l-7.387 7.846a1.288 1.288 0 01-1.813 0 1.272 1.272 0 010-1.808l7.421-7.879a.209.209 0 000-.275L.335 3.558A.194.194 0 000 3.696v21.545a3.158 3.158 0 003.154 3.143h34.692A3.158 3.158 0 0041 25.24V3.696a.2.2 0 00-.336-.138z"/><path d="M20.5 18.464a5.288 5.288 0 003.848-1.654L39.794.706A3.047 3.047 0 0037.845 0H3.168a3.027 3.027 0 00-1.949.706l15.442 16.1a5.288 5.288 0 003.839 1.658z"/>
  </svg>
);

export const IconTwitter = (
  <svg aria-label="Icon Twitter" width="1em" height="1em" viewBox="0 0 40 33.136">
    <path d="M38.816.473a20.077 20.077 0 01-5.207 2.13 7.692 7.692 0 00-5.917-2.6 8.386 8.386 0 00-8.284 8.284 5.217 5.217 0 00.236 1.894A23.242 23.242 0 012.6 1.42a8.17 8.17 0 00-1.184 4.26 7.943 7.943 0 003.787 6.864 9.925 9.925 0 01-3.787-.947 8.6 8.6 0 006.628 8.284 6.555 6.555 0 01-2.131.237 4.616 4.616 0 01-1.656-.237 8.454 8.454 0 007.81 5.917 17.1 17.1 0 01-10.178 3.55H-.004a23.009 23.009 0 0012.544 3.787c15.148 0 23.432-12.781 23.432-23.906V8.046a13.758 13.758 0 004.024-4.26 15.712 15.712 0 01-4.734 1.42 8.754 8.754 0 003.55-4.734"/>
  </svg>
);

export const IconClose = (
  <svg aria-label="Icon Close" width="23.983" height="23.501" viewBox="0 0 23.983 23.501">
    <g data-name="Group 11850" fill="none" stroke="#004b80" strokeWidth="3"><path data-name="Path 9726" d="M1.048 1.073l6.631 6.473 3.554 3.47 1.436 1.4 10.254 10.01"/><path data-name="Line 15" d="M1.569 22.427L22.923 1.073"/></g>
  </svg>
);

export const IconCloseMobile = (
  <svg aria-label="Icon Close" width="15" height="15" viewBox="0 0 23.983 23.501">
    <g data-name="Group 11850" fill="none" stroke="#004b80" strokeWidth="3"><path data-name="Path 9726" d="M1.048 1.073l6.631 6.473 3.554 3.47 1.436 1.4 10.254 10.01"/><path data-name="Line 15" d="M1.569 22.427L22.923 1.073"/></g>
  </svg>
);

export const IconCloseSmall = (
  <svg aria-label="Icon Close" width="1em" height="1em" viewBox="0 0 23.983 23.501">
    <g data-name="Group 11850" fill="none" stroke="#004b80" strokeWidth="3"><path data-name="Path 9726" d="M1.048 1.073l6.631 6.473 3.554 3.47 1.436 1.4 10.254 10.01"/><path data-name="Line 15" d="M1.569 22.427L22.923 1.073"/></g>
  </svg>
);

export const IconWhatsApp = (
  <svg version="1.1" height="1em" viewBox="0 0 512 512">
    <g>
      <path d="M256.064,0h-0.128C114.784,0,0,114.816,0,256c0,56,18.048,107.904,48.736,150.048l-31.904,95.104l98.4-31.456
        C155.712,496.512,204,512,256.064,512C397.216,512,512,397.152,512,256S397.216,0,256.064,0z M405.024,361.504
        c-6.176,17.44-30.688,31.904-50.24,36.128c-13.376,2.848-30.848,5.12-89.664-19.264C189.888,347.2,141.44,270.752,137.664,265.792
        c-3.616-4.96-30.4-40.48-30.4-77.216s18.656-54.624,26.176-62.304c6.176-6.304,16.384-9.184,26.176-9.184
        c3.168,0,6.016,0.16,8.576,0.288c7.52,0.32,11.296,0.768,16.256,12.64c6.176,14.88,21.216,51.616,23.008,55.392
        c1.824,3.776,3.648,8.896,1.088,13.856c-2.4,5.12-4.512,7.392-8.288,11.744c-3.776,4.352-7.36,7.68-11.136,12.352
        c-3.456,4.064-7.36,8.416-3.008,15.936c4.352,7.36,19.392,31.904,41.536,51.616c28.576,25.44,51.744,33.568,60.032,37.024
        c6.176,2.56,13.536,1.952,18.048-2.848c5.728-6.176,12.8-16.416,20-26.496c5.12-7.232,11.584-8.128,18.368-5.568
        c6.912,2.4,43.488,20.48,51.008,24.224c7.52,3.776,12.48,5.568,14.304,8.736C411.2,329.152,411.2,344.032,405.024,361.504z"/>
    </g>
  </svg>
);

export const IconLinkedIn = (
  <svg version="1.1" height="1em" viewBox="0 0 94 94">
    <g>
      <path d="M89,0H5C2.239,0,0,2.239,0,5v84c0,2.761,2.239,5,5,5h84c2.762,0,5-2.239,5-5V5C94,2.239,91.762,0,89,0z M26.414,84.201
        H10.966V34.503h15.447L26.414,84.201L26.414,84.201z M18.693,27.71c-4.951,0-8.959-4.013-8.959-8.958
        c0.001-4.938,4.01-8.952,8.959-8.952c4.939,0,8.951,4.012,8.951,8.952C27.644,23.697,23.632,27.71,18.693,27.71z M84.266,84.2
        H68.832V60.033c0-5.764-0.105-13.178-8.029-13.178c-8.033,0-9.268,6.278-9.268,12.759V84.2H36.099V34.503h14.816v6.79h0.207
        c2.063-3.907,7.103-8.027,14.614-8.027c15.641,0,18.529,10.294,18.529,23.673V84.2L84.266,84.2z"/>
    </g>
  </svg>
);
