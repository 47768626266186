import React from 'react';
import { Route, Switch } from 'react-router';

import { ABOUT_US, HOME, JOB_APPLICATION, JOB_DESCRIPTION, SEARCH } from 'src/app/constants/route';
import { AboutUs } from 'src/app/containers/AboutUs/AboutUs';
import { Home } from 'src/app/containers/Home/Home';
import { NotFound } from 'src/app/containers/NotFound/NotFound';
import { JobDescription } from 'src/app/containers/JobDescription/JobDescription';
import { SearchPage } from 'src/app/containers/SearchPage/SearchPage';

export class Routes extends React.Component {
  render(): JSX.Element {
    return (
      <Switch>
        <Route path={HOME} exact={true} component={Home} />
        <Route path={ABOUT_US} exact={true} component={AboutUs} />
        <Route path={SEARCH} exact={true} component={SearchPage} />
        <Route path={[JOB_DESCRIPTION, JOB_APPLICATION]} exact={true} component={JobDescription} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}
