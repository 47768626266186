import classnames from 'classnames';
import React from 'react';

import { IconFrame } from 'src/app/components/Icon/IconFrame';
import { withStyles } from 'src/app/helpers/styles';

interface MyProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  classNameIcon?: string;
  icon: JSX.Element;
  isCircular?: boolean;
  modifier?: 'clear' | 'gray' | 'primary-lighter' | 'secondary' | 'transparent';
}

@withStyles(require('./ButtonIcon.scss'))
export class ButtonIcon extends React.Component<MyProps> {
  render(): JSX.Element {
    const { className, classNameIcon, icon, isCircular = true, modifier, ...rest } = this.props;
    const classNameModifier = modifier && 'button-icon--' + modifier;
    const classNameRadius = isCircular ? 'radius-circular' : 'radius-small';
    return (
      <button {...rest} className={classnames('fs-24 b-0 p-0 button-icon', classNameRadius, classNameModifier, className)} aria-label="button-icon">
        <IconFrame className={classnames('button-icon__icon', classNameIcon)}>{icon}</IconFrame>
      </button>
    );
  }
}
