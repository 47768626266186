import { withStyles as $withStyles } from '@gdp/react-app-plugin-sass/lib/withStyles';
import React from 'react';

import { WrappedStyles } from 'src/app/components/WrappedStyles/WrappedStyles';
import { AppStyles, InjectedStylesProps } from 'src/app/models/styles';

export const StyleContext = React.createContext(false);

function isAppStyles(value: AppStyles | NodeRequire): value is AppStyles {
  return 'base' in value || 'desktop' in value;
}

export function withStyles(styles: AppStyles | NodeRequire): <C>(WrappedComponent: C) => C {
  function wrappedComponent<C>(WrappedComponent: C): C;
  function wrappedComponent<P>(WrappedComponent: React.ComponentType<P>): React.ComponentClass<P>;
  function wrappedComponent<P>(WrappedComponent: React.ComponentType<P>): React.ComponentClass<P> {
    if (!isAppStyles(styles)) {
      return $withStyles(styles)(WrappedComponent);
    }

    return class MyClassWithStyles extends React.Component<P> {
      render(): JSX.Element {
        return (
          <StyleContext.Consumer>
            {(isMobile) => <WrappedStyles isMobile={isMobile} originalComponent={WrappedComponent} originalProps={this.props} styles={styles} />}
          </StyleContext.Consumer>
        );
      }
    };
  }

  return wrappedComponent;
}

export function withInjectedStyles(styles?: AppStyles | NodeRequire): <C>(WrappedComponent: C) => C {
  function wrappedComponent<C>(WrappedComponent: C): C;
  function wrappedComponent<P extends InjectedStylesProps>(WrappedComponent: React.ComponentType<P>): React.ComponentClass<Pick<P, Exclude<keyof P, keyof InjectedStylesProps>>> {
    class MyClassWithInjectedStyles extends React.Component<P> {
      render(): JSX.Element {
        return (
          <StyleContext.Consumer>
            {(isMobile) => <WrappedComponent {...this.props} isMobile={isMobile} />}
          </StyleContext.Consumer>
        );
      }
    }
    if (!styles) {
      return MyClassWithInjectedStyles;
    }
    return withStyles(styles)(MyClassWithInjectedStyles);
  }

  return wrappedComponent;
}
