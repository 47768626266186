import React from 'react';

interface MyProps {
  className?: string;
  count: number;
}

export class GhostElement extends React.Component<MyProps> {
  render(): JSX.Element[] {
    const { className, count } = this.props;
    const result: JSX.Element[] = [];
    for (let i = 0; i < count; i++) {
      result.push(<div key={'ghost-' + i} className={className} />);
    }
    return result;
  }
}
