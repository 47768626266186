import { client } from '@gdp/react-app/lib/platforms/client';

import React from 'react';
import ReactDOM from 'react-dom';

import { ClientApp } from './ClientApp';
import { ConfigContext } from 'src/app/helpers/config';
import { Config } from 'src/app/models/config';

client<Config>().then(({ compile, config }) => {
  compile(
    <ConfigContext.Provider value={config}>
      <ClientApp />
    </ConfigContext.Provider>,
    (element) => {
      ReactDOM.hydrate(element, document.getElementById('project-root'));
    }
  );
});
