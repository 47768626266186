import classnames from 'classnames';
import React from 'react';

import { withStyles } from 'src/app/helpers/styles';

interface MyProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  modifier?: 'black' | 'white' | 'black-dark' | 'black-light' | 'primary-light' | 'primary' | 'primary-darker' | 'secondary' | 'secondary-darker' | 'tertiary';
}

@withStyles({
  base: [require('./Button.base.scss')],
  desktop: [require('./Button.desktop.scss')]
})
export class Button extends React.Component<MyProps> {
  render(): JSX.Element {
    const { children, className, modifier, ...rest } = this.props;
    const classNameModifier = modifier && 'button--' + modifier;
    return (
      <button {...rest} className={classnames('button radius-small b-0', classNameModifier, className)}>
        {children}
      </button>
    );
  }
}
