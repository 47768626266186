import React from 'react';

import { withStyles } from 'src/app/helpers/styles';

@withStyles(require('./PopupError.scss'))
export class PopupError extends React.Component {
  render(): JSX.Element {
    return (
      <div className="fs-18 popup-error position-absolute ta-center px-20 py-16 bg-white radius-small">
        An error occured. Try again later.
      </div>
    );
  }
}
