import React from 'react';
import { FormattedMessage, WrappedComponentProps as InjectedIntlProps } from 'react-intl';

import { withIntl } from 'src/app/helpers/locale';
import { withStyles } from 'src/app/helpers/styles';

type MyInjectedProps = InjectedIntlProps;

interface MyOwnProps {
  openSearchPage: (query: string) => void;
}

type MyProps = MyOwnProps & MyInjectedProps;

const MAX_TIMEOUT = 1000;
const MAX_CHAR = 3;

@withIntl()
@withStyles({
  base: [require('./HomeHeader.base.scss')],
  desktop: [require('./HomeHeader.desktop.scss')]
})
class MyClassHomeHeader extends React.Component<MyProps> {
  delayedInput: number;

  constructor(props: MyProps) {
    super(props);

    this.onInputChange = this.onInputChange.bind(this);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
  }

  render(): JSX.Element {
    const { intl: { formatMessage } } = this.props;
    return (
      <div className="home-header bg-black ta-center">
        <div className="home-header__container">
          <div className="fs-32 mb-8 fw-500">
            <FormattedMessage id="home.header.title" />
          </div>
          <div className="fs-18 mb-30 home-header__subtitle">
            <FormattedMessage id="home.header.subtitle" />
          </div>
          <form className="d-flex jc-center">
            <input
              onChange={this.onInputChange}
              onKeyDown={this.handleOnKeyDown}
              className="home-header__search flex-1 fs-14 fs@md-16 ta-center"
              type="text"
              placeholder={formatMessage({ id: 'home.header.search' })}>
            </input>
          </form>
        </div>
      </div>
    );
  }

  onInputChange(event: React.FormEvent<HTMLInputElement>): void {
    const inputValue = event.currentTarget.value;
    if (this.delayedInput) {
      clearTimeout(this.delayedInput);
    }
    this.delayedInput = window.setTimeout(() => {
      if (inputValue.length >= MAX_CHAR) {
        this.props.openSearchPage(inputValue);
      }
    }, MAX_TIMEOUT);
  }

  handleOnKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void {
    if (event.keyCode === 13) {
      const inputValue = event.currentTarget.value;
      this.props.openSearchPage(inputValue);
      event.preventDefault();
    }
  }
}

export const HomeHeader = MyClassHomeHeader as React.ComponentClass<MyOwnProps>;
