import React from 'react';

import { LocaleProvider } from 'src/app/components/LocaleProvider/LocaleProvider';
import { Routes } from 'src/app/components/Routes/Routes';
import { Layout } from 'src/app/containers/Layout/Layout';
import { withStyles } from 'src/app/helpers/styles';
import { resizeIframe } from './helpers/iframe';

const INTERVAL_CHECK_HEIGHT = 500;

@withStyles(require('./App.scss'))
export class App extends React.Component {
  lastDocumentHeight: number;

  constructor(props: any) {
    super(props);

    this.resizeIframeIfDocumentHeightChanges = this.resizeIframeIfDocumentHeightChanges.bind(this);
  }

  componentDidMount(): void {
    this.lastDocumentHeight = document.body.scrollHeight;
    resizeIframe();
    setInterval(this.resizeIframeIfDocumentHeightChanges, INTERVAL_CHECK_HEIGHT);
  }

  render(): JSX.Element {
    return (
      <LocaleProvider>
        <Layout>
          <Routes />
        </Layout>
      </LocaleProvider>
    );
  }

  resizeIframeIfDocumentHeightChanges(): void {
    if (this.lastDocumentHeight !== document.body.scrollHeight) {
      resizeIframe();
      this.lastDocumentHeight = document.body.scrollHeight;
    }
  }
}
