export class FilterItem {
  static mapFromFilterItemCategoryQueryResult(itemQuery: FilterItemCategoryQueryResult): FilterItem {
    return {
      name: itemQuery.name,
      totalItems: itemQuery.jobs,
      isSelected: false
    };
  }

  name: string;
  isSelected: boolean;
  totalItems: number;
}

export class FilterItemWithId extends FilterItem {
  static mapFromFilterItemQueryResult(itemQuery: FilterItemQueryResult): FilterItemWithId {
    return {
      id: itemQuery.id,
      name: itemQuery.name,
      totalItems: itemQuery.count,
      isSelected: false
    };
  }

  id: string;
}

export interface SearchSidebarResult {
  error: boolean;
  data: {
    category: FilterItemCategoryQueryResult[];
    location: FilterItemQueryResult[];
    organization: FilterItemQueryResult[];
  };
}

export interface FilterItemCategoryQueryResult {
  name: string;
  jobs: number;
  image: string;
}

export interface FilterItemQueryResult {
  id: string;
  name: string;
  count: number;
}

export interface FilterItemsDataResult {
  error: boolean;
  data: FilterItemQueryResult[];
}

export interface FilterItemsDataCategoryResult {
  error: boolean;
  data: FilterItemCategoryQueryResult[];
}

export enum FILTER_TYPE {
  COMPANY = 'COMPANY',
  LOCATION = 'LOCATION',
  CATEGORY = 'CATEGORY'
}

export const queryParams = {
  location: 'locationId',
  company: 'organizationId',
  category: 'category',
  keyword: 'keyword'
};
