// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../images/header_background.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@media (min-width:544px){.home-header{color:#fff;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-position:50%;background-size:cover;margin-left:0;margin-right:0}.home-header__container{padding:71px 0}.home-header__search{max-width:890px;margin:0 67px;height:50px;background:#fff}.home-header__subtitle{font-family:OpenSans;font-weight:500;color:#fff}}", ""]);
// Exports
module.exports = exports;
