import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ClientStyleProvider } from '@gdp/react-app-plugin-sass/lib/ClientStyleProvider';

import { App } from 'src/app';
import { withStyles } from 'src/app/helpers/styles';

const AppWithStyles = withStyles({
  base: [
    require('src/styles/apps/_apps.media-sm.scss'),
    require('src/styles/apps/_apps.media-xs.scss'),
    require('src/styles/apps/_apps.base.scss')
  ],
  desktop: [
    require('src/styles/apps/_apps.media-xl.scss'),
    require('src/styles/apps/_apps.media-lg.scss'),
    require('src/styles/apps/_apps.media-md.scss')
  ]
})(App);

export function ClientApp(): JSX.Element {
  return (
    <BrowserRouter>
      <HelmetProvider>
        <ClientStyleProvider>
          <AppWithStyles />
        </ClientStyleProvider>
      </HelmetProvider>
    </BrowserRouter>
  );
}
