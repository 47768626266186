import React from 'react';
import { FormattedMessage } from 'react-intl';
import { titleCase } from 'title-case';

import { ResponsiveContainer } from 'src/app/components/ResponsiveContainer/ResponsiveContainer';
import { withStyles } from 'src/app/helpers/styles';
import { CategoryItem } from 'src/app/models/category';

export const defaultImage = require('src/images/header_background.png');

interface MyProps {
  categoryItem: CategoryItem;
  openSearchCategory: (categoryName: string) => void;
}

@withStyles({
  base: [require('./CategoryCard.base.scss')],
  desktop: [require('./CategoryCard.desktop.scss')]
})
export class CategoryCard extends React.Component<MyProps> {
  constructor(props: MyProps) {
    super(props);

    this.handleOnClick = this.handleOnClick.bind(this);
  }

  render(): JSX.Element {
    const { categoryItem: { categoryName, totalJobs, srcImg } } = this.props;
    const img = srcImg || defaultImage.default;
    const style: React.CSSProperties = {
      backgroundImage: `url(${img})`,
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover'
    };

    return (
      <div className="cursor-pointer" onClick={this.handleOnClick}>
        <ResponsiveContainer modifier="none" className="category-card__wrapper">
          <div style={style}></div>
          <div className="bg-black opacity-50"></div>
          <div className="d-flex fld-column fg-white m-16">
            <div className="category-card__name flex-1 d-flex ai-end fs-18 mb-4">
              {titleCase(categoryName)}
            </div>
            <div className="flex-1 fs-14 mt-4 ta-center">
              <div className="category-card__total-jobs py-2">
                <FormattedMessage id="home.content.category.jobs" values={{ totalJobs }}/>
              </div>
            </div>
          </div>
        </ResponsiveContainer>
      </div>
    );
  }

  handleOnClick(): void {
    const { openSearchCategory, categoryItem } = this.props;

    openSearchCategory(categoryItem.categoryName);
  }
}
