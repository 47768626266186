import classnames from 'classnames';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { RouteComponentProps } from 'react-router-dom';

import { ButtonIcon } from 'src/app/components/Button/ButtonIcon';
import { IconFilter } from 'src/app/components/Icon/IconFilter';
import { IconSearch } from 'src/app/components/Icon/IconSearch';
import { SearchResult } from 'src/app/components/SearchResult/SearchResult';
import { SearchSidebar } from 'src/app/components/SearchSidebar/SearchSidebar';
import { withInjectedStyles } from 'src/app/helpers/styles';
import { FilterItem, SearchSidebarResult, FilterItemWithId, FILTER_TYPE, queryParams, FilterItemsDataResult, FilterItemsDataCategoryResult } from 'src/app/models/filter';
import { ResponsiveContainer } from 'src/app/components/ResponsiveContainer/ResponsiveContainer';
import { FormattedMessage } from 'react-intl';
import { CategoryItem } from 'src/app/models/category';
import { SEARCH, queryString } from 'src/app/constants/route';
import { withQuery } from 'src/app/helpers/query';
import { QueryInjectedProps } from 'src/app/models/query';
import { Loader } from 'src/app/components/Loader/Loader';
import { SearchResultQueryResult, SearchResultItem } from 'src/app/models/searchResult';
import { defaultImage } from 'src/app/components/CategoryCard/CategoryCard';
import { InfiniteScrollListener } from 'src/app/components/InfiniteComponents/InfiniteScrollListener';
import { Button } from 'src/app/components/Button/Button';
import { scrollTopIframe } from 'src/app/helpers/iframe';
import { InjectedStylesProps } from 'src/app/models/styles';
import { isMobile } from 'src/app/helpers/device';

interface FilterItemWithIdList {
  showLimit: number;
  items: FilterItemWithId[];
}

interface FilterItemList {
  showLimit: number;
  items: FilterItem[];
}

interface FilterItemState {
  [FILTER_TYPE.COMPANY]: FilterItemWithIdList;
  [FILTER_TYPE.CATEGORY]: FilterItemList;
  [FILTER_TYPE.LOCATION]: FilterItemWithIdList;
}

interface MyInjectedProps extends RouteComponentProps, InjectedStylesProps {
  searchSidebarData: QueryInjectedProps<SearchSidebarResult>;
  searchResultData: QueryInjectedProps<SearchResultQueryResult>;
  companySearchData: QueryInjectedProps<FilterItemsDataResult>;
  locationSearchData: QueryInjectedProps<FilterItemsDataResult>;
  categorySearchData: QueryInjectedProps<FilterItemsDataCategoryResult>;
}

type MyProps = MyInjectedProps;

interface MyState {
  filterItemState: FilterItemState;
  categoryHeader: CategoryItem | null;
  isDrawerOpen: boolean;
  querySearch: string;
  hasMoreSearchResult: boolean;
  pageSearchResult: number;
  searchResultItems: SearchResultItem[];
}

const initPage = 0;

@withInjectedStyles({
  base: [require('./SearchPage.base.scss')],
  desktop: [require('./SearchPage.desktop.scss')]
})
@withQuery('/search/sidebar', {
  name: 'searchSidebarData'
})
@withQuery('/search', {
  name: 'searchResultData'
})
@withQuery('/search/organization', {
  name: 'companySearchData'
})
@withQuery('/search/location', {
  name: 'locationSearchData'
})
@withQuery('/search/category', {
  name: 'categorySearchData'
})
class MySearchPage extends React.Component<MyProps, MyState> {
  private scrollingDiv?: HTMLDivElement;
  private hasFetch: boolean;

  constructor(props: MyProps) {
    super(props);

    const initFilterState = this.getInitFilterState() as MyState;
    this.state = {
      ...initFilterState,
      isDrawerOpen: false,
      pageSearchResult: initPage,
      searchResultItems: []
    };

    const params = this.generateParamsFromQueryString();

    props.searchResultData.setParam({
      queryString: `${initPage}${params}`
    });

    props.companySearchData.setParam({
      queryString: '?page=all'
    });

    props.locationSearchData.setParam({
      queryString: '?page=all'
    });

    props.categorySearchData.setParam({
      queryString: '?page=all'
    });

    this.handleCheckboxChangeWithId = this.handleCheckboxChangeWithId.bind(this);
    this.handleCategoryCheckboxChange = this.handleCategoryCheckboxChange.bind(this);
    this.handleToggleDrawer = this.handleToggleDrawer.bind(this);
    this.handleSubmitSearch = this.handleSubmitSearch.bind(this);
    this.handleQuerySearchChange = this.handleQuerySearchChange.bind(this);
    this.handleResetFilters = this.handleResetFilters.bind(this);
    this.handleFetchMoreResultData = this.handleFetchMoreResultData.bind(this);
    this.setScrollingContainer = this.setScrollingContainer.bind(this);
    this.handleApplyFilters = this.handleApplyFilters.bind(this);
  }

  componentDidUpdate(prevProps: MyProps): void {
    if (prevProps.location.search !== this.props.location.search) {
      const initFilterState = this.getInitFilterState() as MyState;
      this.setState(initFilterState);
    }

    if (!prevProps.searchSidebarData.resultData && this.props.searchSidebarData.resultData) {
      const initFilterState = this.getInitFilterState() as MyState;
      this.setState(initFilterState);
    }

    if (prevProps.searchSidebarData.isLoading && !this.props.searchSidebarData.isLoading) {
      const initFilterState = this.getInitFilterState() as MyState;
      this.setState(initFilterState);
    }

    if (prevProps.companySearchData.isLoading && !this.props.companySearchData.isLoading) {
      const companyFilterItems = this.handleInitFilterWithId(FILTER_TYPE.COMPANY);

      this.setState({
        filterItemState: {
          ...this.state.filterItemState,
          [FILTER_TYPE.COMPANY]: companyFilterItems
        }
      });
    }

    if (prevProps.locationSearchData.isLoading && !this.props.locationSearchData.isLoading) {
      const locationFilterItems = this.handleInitFilterWithId(FILTER_TYPE.LOCATION);

      this.setState({
        filterItemState: {
          ...this.state.filterItemState,
          [FILTER_TYPE.LOCATION]: locationFilterItems
        }
      });
    }

    if (prevProps.categorySearchData.isLoading && !this.props.categorySearchData.isLoading) {
      const categoryFilterItems = this.handleInitFilter(FILTER_TYPE.CATEGORY);

      this.setState({
        filterItemState: {
          ...this.state.filterItemState,
          [FILTER_TYPE.CATEGORY]: categoryFilterItems
        }
      });
    }

    if (prevProps.searchResultData.isLoading && !this.props.searchResultData.isLoading && this.props.searchResultData.resultData) {
      const { searchResultItems } = this.state;
      const currentSearchResultItemsCount = searchResultItems.length;
      if (currentSearchResultItemsCount > 0) {
        const lastSearchResultItem = searchResultItems[currentSearchResultItemsCount - 1];
        const nextSearchResult = SearchResultItem.mapFromSearchResultItemQueryResult(this.props.searchResultData.resultData.data.searchResult);
        const firstNextSearchResultItem = nextSearchResult[0];

        if (lastSearchResultItem.companyName === firstNextSearchResultItem.companyName) {
          const joinedSearchResult: SearchResultItem = {
            companyName: lastSearchResultItem.companyName,
            logo: lastSearchResultItem.logo,
            url: lastSearchResultItem.url,
            jobItems: lastSearchResultItem.jobItems.concat(firstNextSearchResultItem.jobItems)
          };

          const newSearchResult = searchResultItems.slice(0, currentSearchResultItemsCount - 1).concat([joinedSearchResult]).concat(nextSearchResult.slice(1, nextSearchResult.length));

          this.setState({
            searchResultItems: newSearchResult,
            pageSearchResult: this.state.pageSearchResult + 1,
            hasMoreSearchResult: !this.props.searchResultData.resultData.data.last
          });
        } else {
          const newSearchResult = searchResultItems.concat(SearchResultItem.mapFromSearchResultItemQueryResult(this.props.searchResultData.resultData.data.searchResult));
          this.setState({
            searchResultItems: newSearchResult,
            pageSearchResult: this.state.pageSearchResult + 1,
            hasMoreSearchResult: !this.props.searchResultData.resultData.data.last
          });
        }
      } else {
        const newSearchResult = searchResultItems.concat(SearchResultItem.mapFromSearchResultItemQueryResult(this.props.searchResultData.resultData.data.searchResult));
        this.setState({
          searchResultItems: newSearchResult,
          pageSearchResult: this.state.pageSearchResult + 1,
          hasMoreSearchResult: !this.props.searchResultData.resultData.data.last
        });
      }
    }
  }

  render(): JSX.Element {
    const { searchSidebarData } = this.props;

    if (searchSidebarData.isLoading && !searchSidebarData.resultData) {
      return <Loader />;
    } else if (searchSidebarData.resultData) {
      return (
        <>
          <Helmet>
            <title>GDPV Career Page - Search</title>
          </Helmet>
          {this.renderSideDrawer()}
          <div className="bg-gray-lighter">
            <div className="search-page__container" ref={this.setScrollingContainer}>
              <div className="search-page__sidebar d-none d@md-block pt-30">
                <div className="search-page__sidebar--container">
                  {this.renderSearchSidebar()}
                </div>
              </div>
              {this.renderSearchResult()}
            </div>
          </div>
        </>
      );
    } else {
      return <div>ERROR</div>;
    }
  }

  renderSearchResult(): JSX.Element {
    const { searchResultData } = this.props;
    const { querySearch, searchResultItems } = this.state;

    if (searchResultData.isLoading && searchResultItems.length === 0) {
      return (
        <div className="search-page__result--content">
          <Loader />
        </div>
      );
    } else if (searchResultData.error) {
      return (
        <div className="search-page__result--content">
          <div>ERROR</div>
        </div>
      );
    } else {
      return (
        <>
          {this.renderContentHeader()}
          <div className="search-page__result--content">
            <div className="search-page__result--content--container">
              <div className="d-flex d@md-none mb-42 ai-center">
                <form className="search-page__search-form w-100 px-10 py-12 fs-14 d-flex" onSubmit={this.handleSubmitSearch}>
                  <input
                    onChange={this.handleQuerySearchChange}
                    className="search-page__search-input flex-1 b-0 bg-transparent outline-0 "
                    type="text"
                    placeholder="SEARCH JOBS"
                    value={querySearch}>
                  </input>
                  <ButtonIcon className="fs-12 ls@md-1-5" icon={IconSearch} modifier="clear" type="submit"/>
                </form>
                <div className="ml-18">
                  <ButtonIcon className="fs-12 ls@md-1-5" icon={IconFilter} onClick={this.handleToggleDrawer} modifier="clear" />
                </div>
              </div>
              {
                searchResultItems.length === 0
                  ? this.renderEmptyResult()
                  : <SearchResult
                    searchResultItems={searchResultItems}
                  />
              }
              {this.renderSearchResultFooter()}
            </div>
          </div>
        </>
      );
    }
  }

  renderEmptyResult(): JSX.Element {
    return (
      <div className="search-page__empty-result">
        <div className="fg-gray fs-18 search-page__empty-result--text">
          <FormattedMessage id="search.result.empty" />
        </div>
        <Button
          className={classnames('button--format-32 tt-uppercase fs-14 b-1 fg-white search-page__button mt-30')}
          modifier="primary-light"
          type="button"
          onClick={this.handleResetFilters}
        >
          <FormattedMessage id="search.result.reset" />
        </Button>
      </div>
    );
  }

  renderSearchResultFooter(): JSX.Element | null {
    const { searchResultData } = this.props;
    const { hasMoreSearchResult } = this.state;

    if (searchResultData.isLoading) {
      return <Loader />;
    } else if (hasMoreSearchResult && this.scrollingDiv) {
      return <InfiniteScrollListener onLoadMore={this.handleFetchMoreResultData} scrollComponent={this.scrollingDiv} threshold={100}/>;
    } else {
      return null;
    }
  }

  renderSideDrawer(): JSX.Element {
    const { isDrawerOpen } = this.state;

    return (
      <>
        {isDrawerOpen && <div className="search-page__overlay position-fixed top-0 bottom-0 left-0 right-0" onClick={this.handleToggleDrawer}/>}
        <aside className={classnames('search-page__side-drawer position-fixed top-0 bottom-0 bg-gray-lighter', { 'search-page__side-drawer--is-open': isDrawerOpen })}>
          <div className="search-page__side-drawer--container mx-16">
            {this.renderSearchSidebar()}
          </div>
        </aside>
      </>
    );
  }

  renderSearchSidebar(): JSX.Element {
    const { filterItemState, querySearch } = this.state;

    const companyFilterItems = filterItemState[FILTER_TYPE.COMPANY].items.slice(0, filterItemState[FILTER_TYPE.COMPANY].showLimit);
    const locationFilterItems = filterItemState[FILTER_TYPE.LOCATION].items.slice(0, filterItemState[FILTER_TYPE.LOCATION].showLimit);
    const categoryFilterItems = filterItemState[FILTER_TYPE.CATEGORY].items.slice(0, filterItemState[FILTER_TYPE.CATEGORY].showLimit);

    const sortedCompanyFilterItems = [...filterItemState[FILTER_TYPE.COMPANY].items].sort((a, b) => a.name > b.name ? 1 : -1);
    const sortedLocationFilterItems = [...filterItemState[FILTER_TYPE.LOCATION].items].sort((a, b) => a.name > b.name ? 1 : -1);
    const sortedCategoryFilterItems = [...filterItemState[FILTER_TYPE.CATEGORY].items].sort((a, b) => a.name > b.name ? 1 : -1);
    return (
      <SearchSidebar
        companyFilterItems={companyFilterItems}
        allCompanyFilterItems={sortedCompanyFilterItems}
        onCompanyCheckboxChange={(e, directApply) => this.handleCheckboxChangeWithId(e, FILTER_TYPE.COMPANY, directApply)}
        locationFilterItems={locationFilterItems}
        allLocationFilterItems={sortedLocationFilterItems}
        onLocationCheckboxChange={(e, directApply) => this.handleCheckboxChangeWithId(e, FILTER_TYPE.LOCATION, directApply)}
        categoryFilterItems={categoryFilterItems}
        allCategoryFilterItems={sortedCategoryFilterItems}
        onCategoryCheckboxChange={this.handleCategoryCheckboxChange}
        onQuerySearchChange={this.handleQuerySearchChange}
        onSubmitSearch={this.handleSubmitSearch}
        searchValue={querySearch}
        onResetFilterClick={this.handleResetFilters}
        onApplyFilterClick={this.handleApplyFilters}
        onCloseIconClick={this.handleToggleDrawer} />
    );
  }

  renderContentHeader(): JSX.Element | null {
    const { categoryHeader } = this.state;

    if (!categoryHeader) {
      return null;
    }

    const img = categoryHeader.srcImg || defaultImage.default;

    const style: React.CSSProperties = {
      backgroundImage: `url(${img})`,
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover'
    };

    return (
      <div className="search-page__result--header">
        <ResponsiveContainer modifier="none" className="search-page__header--image">
          <div style={style}></div>
          <div className="bg-black opacity-50"></div>
          <div className="d-flex fld-column fg-white mx-30 mx@md-60">
            <div className="flex-1 d-flex ai-end fs-32 mb-4">
              {categoryHeader.categoryName.toUpperCase()}
            </div>
            <div className="flex-1 fs-14 mt-4 ta-center">
              <div className="search-page__header--total-jobs py-2">
                <FormattedMessage id="home.content.category.jobs" values={{ totalJobs: categoryHeader.totalJobs }}/>
              </div>
            </div>
          </div>
        </ResponsiveContainer>
      </div>
    );
  }

  getInitFilterState(): Partial<MyState> {
    const searchParams = new URLSearchParams(this.props.location.search);

    const querySearch = searchParams.get(queryString.keyword) || '';
    const companyFilterItems = this.handleInitFilterWithId(FILTER_TYPE.COMPANY);
    const locationFilterItems = this.handleInitFilterWithId(FILTER_TYPE.LOCATION);
    const categoryFilterItems = this.handleInitFilter(FILTER_TYPE.CATEGORY);

    const categoryHeader = searchParams.getAll('category').length === 1 && this.props.searchSidebarData.resultData
      ? CategoryItem.getCategoryItemFromFilterItemCategoryQueryResult(this.props.searchSidebarData.resultData.data.category, searchParams.getAll('category')[0])
      : null;

    const filterItemState: FilterItemState = {
      [FILTER_TYPE.CATEGORY]: categoryFilterItems,
      [FILTER_TYPE.COMPANY]: companyFilterItems,
      [FILTER_TYPE.LOCATION]: locationFilterItems
    };

    return {
      filterItemState,
      categoryHeader,
      querySearch
    };
  }

  getFilterFromParams(filterType: FILTER_TYPE): string[] {
    const searchParams = new URLSearchParams(this.props.location.search);

    switch (filterType) {
      case FILTER_TYPE.CATEGORY:
        return searchParams.getAll(queryString.category);
      case FILTER_TYPE.COMPANY:
        return searchParams.getAll(queryString.company);
      case FILTER_TYPE.LOCATION:
        return searchParams.getAll(queryString.location);
    }
  }

  getFilterItemsWithIdFromData(filterType: FILTER_TYPE): FilterItemWithId[] {
    const { searchSidebarData: { resultData } } = this.props;
    if (!resultData) {
      return [];
    }

    if (filterType === FILTER_TYPE.COMPANY) {
      return resultData.data.organization.map((value) => {
        return FilterItemWithId.mapFromFilterItemQueryResult(value);
      });
    } else if (filterType === FILTER_TYPE.LOCATION) {
      return resultData.data.location.map((value) => {
        return FilterItemWithId.mapFromFilterItemQueryResult(value);
      });
    } else {
      return [];
    }
  }

  getFilterItemsFromData(filterType: FILTER_TYPE): FilterItem[] {
    const { searchSidebarData: { resultData } } = this.props;
    if (!resultData) {
      return [];
    }

    if (filterType === FILTER_TYPE.CATEGORY) {
      return resultData.data.category.map((value) => {
        return FilterItem.mapFromFilterItemCategoryQueryResult(value);
      });
    } else {
      return [];
    }
  }

  getAllFilterItemsWithIdFromData(filterType: FILTER_TYPE): FilterItemWithId[] {
    const { companySearchData, locationSearchData } = this.props;

    const resultData = filterType === FILTER_TYPE.COMPANY ? companySearchData.resultData : locationSearchData.resultData;

    if (!resultData) {
      return [];
    }

    return resultData.data.map((value) => {
      return FilterItemWithId.mapFromFilterItemQueryResult(value);
    });
  }

  getAllFilterItemsFromData(filterType: FILTER_TYPE): FilterItem[] {
    const { categorySearchData } = this.props;

    const resultData = filterType === FILTER_TYPE.CATEGORY && categorySearchData.resultData;

    if (!resultData) {
      return [];
    }

    return resultData.data.map((value) => {
      return FilterItem.mapFromFilterItemCategoryQueryResult(value);
    });
  }

  handleInitFilterWithId(filterType: FILTER_TYPE): FilterItemWithIdList {
    const filterItems = this.getFilterItemsWithIdFromData(filterType);
    const showLimit = filterItems.length;
    const allFilterItems = this.getAllFilterItemsWithIdFromData(filterType);
    const filterId = this.getFilterFromParams(filterType);

    allFilterItems.forEach((i) => {
      if (!filterItems.find((j) => j.id === i.id)) {
        filterItems.push(i);
      }
    });

    const selectedFilterItems = filterItems.map((item) => {
      if (filterId.find((id) => id.toLowerCase() === item.id.toLocaleLowerCase())) {
        return {
          ...item,
          isSelected: true
        };
      }
      return {
        ...item,
        isSelected: false
      };
    });

    return {
      showLimit,
      items: selectedFilterItems
    };
  }

  handleInitFilter(filterType: FILTER_TYPE): FilterItemList {
    const filterItems = this.getFilterItemsFromData(filterType);
    const showLimit = filterItems.length;
    const allFilterItems = this.getAllFilterItemsFromData(filterType);
    const filterName = this.getFilterFromParams(filterType);

    allFilterItems.forEach((i) => {
      if (!filterItems.find((j) => j.name === i.name)) {
        filterItems.push(i);
      }
    });

    const selectedFilterItems = filterItems.map((item) => {
      if (filterName.find((name) => name.toLowerCase() === item.name.toLocaleLowerCase())) {
        return {
          ...item,
          isSelected: true
        };
      }
      return {
        ...item,
        isSelected: false
      };
    });

    return {
      showLimit,
      items: selectedFilterItems
    };
  }

  handleToggleDrawer(): void {
    this.setState({
      isDrawerOpen: !this.state.isDrawerOpen
    });
  }

  handleCheckboxChangeWithId(name: string, filterType: FILTER_TYPE, directApply: boolean): void {
    const { filterItemState } = this.state;
    const id = name;

    const newFilterItemState = {
      ...filterItemState,
      [filterType]: {
        showLimit: filterItemState[filterType].showLimit,
        items: this.handleCheckboxChangeStateWithId(id, filterItemState[filterType].items as FilterItemWithId[])
      }
    };

    if (directApply) {
      this.setState({
        filterItemState: newFilterItemState
      }, () => {
        this.handleSubmitSearch();
      });
    } else {
      this.setState({
        filterItemState: newFilterItemState
      });
    }
  }

  handleCategoryCheckboxChange(name: string, directApply: boolean): void {
    const { filterItemState } = this.state;

    const newFilterItemState = {
      ...filterItemState,
      [FILTER_TYPE.CATEGORY]: {
        showLimit: filterItemState[FILTER_TYPE.CATEGORY].showLimit,
        items: this.handleCheckboxChangeState(name, filterItemState[FILTER_TYPE.CATEGORY].items)
      }
    };

    if (directApply) {
      this.setState({
        filterItemState: newFilterItemState
      }, () => {
        this.handleSubmitSearch();
      });
    } else {
      this.setState({
        filterItemState: newFilterItemState
      });
    }
  }

  handleCheckboxChangeState(name: string, filterItemsState: FilterItem[]): FilterItem[] {
    return filterItemsState.map((item): FilterItem => {
      if (item.name === name) {
        return {
          ...item,
          isSelected: !item.isSelected
        };
      }
      return item;
    });
  }

  handleCheckboxChangeStateWithId(name: string, filterItemsState: FilterItemWithId[]): FilterItemWithId[] {
    return filterItemsState.map((item): FilterItemWithId => {
      if (item.name === name) {
        return {
          ...item,
          isSelected: !item.isSelected
        };
      }
      return item;
    });
  }

  handleQuerySearchChange(event: React.FormEvent<HTMLInputElement>): void {
    this.setState({
      querySearch: event.currentTarget.value
    });
  }

  handleSubmitSearch(event?: React.FormEvent<HTMLFormElement>): void {
    const { filterItemState } = this.state;

    const companyParams = filterItemState[FILTER_TYPE.COMPANY].items.reduce((currentValue, item) => {
      if (item.isSelected) {
        return `${currentValue}&${queryString.company}=${item.id.toLowerCase()}`;
      }
      return currentValue;
    }, '');

    const locationParams = filterItemState[FILTER_TYPE.LOCATION].items.reduce((currentValue, item) => {
      if (item.isSelected) {
        return `${currentValue}&${queryString.location}=${item.id.toLowerCase()}`;
      }
      return currentValue;
    }, '');

    const categoryParams = filterItemState[FILTER_TYPE.CATEGORY].items.reduce((currentValue, item) => {
      if (item.isSelected) {
        return `${currentValue}&${queryString.category}=${item.name.toLowerCase()}`;
      }
      return currentValue;
    }, '');

    this.props.history.push({
      pathname: SEARCH,
      search: `${queryString.keyword}=${this.state.querySearch}${companyParams}${locationParams}${categoryParams}`
    });

    this.props.searchResultData.fetch({
      queryString: `${initPage}${this.generateParamsFromState()}`
    });

    this.setState({
      pageSearchResult: initPage,
      searchResultItems: []
    });

    this.handleRefetchFilterData();

    if (!isMobile()) {
      scrollTopIframe();
    }

    if (event) {
      event.preventDefault();
    }
  }

  generateParamsFromQueryString(): string {
    const initCompanyParams = `&${queryParams.company}=`;
    const companyParams = this.getFilterFromParams(FILTER_TYPE.COMPANY).reduce((currentValue, item) => {
      if (currentValue === initCompanyParams) {
        return `${currentValue}${item}`;
      }
      return `${currentValue},${item}`;
    }, initCompanyParams);

    const initLocationParams = `&${queryParams.location}=`;
    const locationParams = this.getFilterFromParams(FILTER_TYPE.LOCATION).reduce((currentValue, item) => {
      if (currentValue === initLocationParams) {
        return `${currentValue}${item}`;
      }
      return `${currentValue},${item}`;
    }, initLocationParams);

    const initCategoryParams = `&${queryParams.category}=`;
    const categoryParams = this.getFilterFromParams(FILTER_TYPE.CATEGORY).reduce((currentValue, item) => {
      if (currentValue === initCategoryParams) {
        return `${currentValue}${item}`;
      }
      return `${currentValue},${item}`;
    }, initCategoryParams);

    const searchParams = new URLSearchParams(this.props.location.search);
    const keyword = searchParams.get(queryString.keyword) || '';

    return `?${queryParams.keyword}=${keyword}${companyParams}${locationParams}${categoryParams}`;
  }

  generateParamsFromState(): string {
    const { filterItemState } = this.state;

    const initCompanyParams = `&${queryParams.company}=`;
    const companyParams = filterItemState[FILTER_TYPE.COMPANY].items.reduce((currentValue, item) => {
      if (item.isSelected) {
        if (currentValue === initCompanyParams) {
          return `${currentValue}${item.id.toLowerCase()}`;
        }
        return `${currentValue},${item.id.toLowerCase()}`;
      }
      return currentValue;
    }, initCompanyParams);

    const initLocationParams = `&${queryParams.location}=`;
    const locationParams = filterItemState[FILTER_TYPE.LOCATION].items.reduce((currentValue, item) => {
      if (item.isSelected) {
        if (currentValue === initLocationParams) {
          return `${currentValue}${item.id.toLowerCase()}`;
        }
        return `${currentValue},${item.id.toLowerCase()}`;
      }
      return currentValue;
    }, initLocationParams);

    const initCategoryParams = `&${queryParams.category}=`;
    const categoryParams = filterItemState[FILTER_TYPE.CATEGORY].items.reduce((currentValue, item) => {
      if (item.isSelected) {
        if (currentValue === initCategoryParams) {
          return `${currentValue}${item.name.toLowerCase()}`;
        }
        return `${currentValue},${item.name.toLowerCase()}`;
      }
      return currentValue;
    }, initCategoryParams);

    return `?${queryParams.keyword}=${this.state.querySearch}${companyParams}${locationParams}${categoryParams}`;
  }

  handleResetFilters(): void {
    const { filterItemState } = this.state;

    const resetedCategoryFilter = {
      items: filterItemState[FILTER_TYPE.CATEGORY].items.map((item) => {
        return {
          ...item,
          isSelected: false
        };
      }),
      showLimit: filterItemState[FILTER_TYPE.CATEGORY].showLimit
    };

    const resetedLocationFilter = {
      items: filterItemState[FILTER_TYPE.LOCATION].items.map((item) => {
        return {
          ...item,
          isSelected: false
        };
      }),
      showLimit: filterItemState[FILTER_TYPE.LOCATION].showLimit
    };

    const resetedCompanyFilter = {
      items: filterItemState[FILTER_TYPE.COMPANY].items.map((item) => {
        return {
          ...item,
          isSelected: false
        };
      }),
      showLimit: filterItemState[FILTER_TYPE.COMPANY].showLimit
    };

    const newFilterState: FilterItemState = {
      [FILTER_TYPE.CATEGORY]: resetedCategoryFilter,
      [FILTER_TYPE.LOCATION]: resetedLocationFilter,
      [FILTER_TYPE.COMPANY]: resetedCompanyFilter
    };

    this.setState({
      querySearch: '',
      filterItemState: newFilterState
    }, () => {
      this.props.history.push({
        pathname: SEARCH,
        search: `${queryString.keyword}=`
      });
      this.handleSubmitSearch();
    });
  }

  handleRefetchFilterData(): void {
    const { searchSidebarData, companySearchData, locationSearchData, categorySearchData } = this.props;

    searchSidebarData.fetch({
      queryString: `?${queryParams.keyword}=${this.state.querySearch}`
    });

    companySearchData.fetch({
      queryString: `?${queryParams.keyword}=${this.state.querySearch}&page=all`
    });

    locationSearchData.fetch({
      queryString: `?${queryParams.keyword}=${this.state.querySearch}&page=all`
    });

    categorySearchData.fetch({
      queryString: `?${queryParams.keyword}=${this.state.querySearch}&page=all`
    });
  }

  handleFetchMoreResultData(): void {
    if (!this.hasFetch) {
      this.props.searchResultData.fetch({
        queryString: `${this.state.pageSearchResult}${this.generateParamsFromQueryString()}`
      }).then(() => {
        this.hasFetch = false;
      });
    }
    this.hasFetch = true;
  }

  private setScrollingContainer(ref: HTMLDivElement): void {
    this.scrollingDiv = ref;
    this.forceUpdate();
  }

  handleApplyFilters(filterItems: FilterItem[], filterType: FILTER_TYPE): void {
    if (filterType === FILTER_TYPE.CATEGORY) {
      const filterItemsState = this.state.filterItemState[FILTER_TYPE.CATEGORY];
      const newFilterItemsStateItems = filterItemsState.items.map((item: FilterItem) => {
        const selectedItem = filterItems.find((filterItem) => filterItem.name === item.name);
        const isSelected = selectedItem ? selectedItem.isSelected : item.isSelected;
        return {
          ...item,
          isSelected
        };
      });

      const newFilterItemState: FilterItemList = {
        showLimit: filterItemsState.showLimit,
        items: newFilterItemsStateItems
      };

      this.setState({
        filterItemState: {
          ...this.state.filterItemState,
          [FILTER_TYPE.CATEGORY]: newFilterItemState
        }
      }, this.handleSubmitSearch);
    } else {
      const filterItemsState = this.state.filterItemState[filterType];

      const newFilterItemsStateItems = filterItemsState.items.map((item: FilterItemWithId) => {
        const selectedItem = filterItems.find((filterItem) => filterItem.name === item.name);
        const isSelected = selectedItem ? selectedItem.isSelected : item.isSelected;
        return {
          ...item,
          isSelected
        };
      });

      const newFilterItemState: FilterItemWithIdList = {
        showLimit: filterItemsState.showLimit,
        items: newFilterItemsStateItems
      };

      this.setState({
        filterItemState: {
          ...this.state.filterItemState,
          [filterType]: newFilterItemState
        }
      }, this.handleSubmitSearch);
    }
  }
}

export const SearchPage = MySearchPage as React.ComponentClass;
