export const intlMessagesEn = {
  'home.header.title': 'Jobs',
  'home.header.subtitle': 'Find the right opportunity to grow with us',
  'home.header.search': 'SEARCH JOBS',
  'home.content.category.jobs': '{totalJobs} JOBS',
  'search.sidebar.header.title': 'JOBS',
  'search.sidebar.header.subtitle': 'Find the right opportunity to grow with us',
  'search.sidebar.header.filters': 'Filters',
  'search.sidebar.reset.filters': 'RESET FILTER',
  'search.result.empty': 'We currently don’t have any available positions matching your criteria. please try searching with a different set of filters.',
  'search.result.reset': 'RESET FILTERS',
  'job.description.header.apply': 'Apply',
  'job.description.header.share': 'Share',
  'job.description.header.morejobs': 'More Jobs',
  'job.application.content.submityourapplication': 'Submit Your Apllication',
  'job.application.content.attachresumecv': 'ATTACH RESUME/CV',
  'job.application.content.fullName': 'Full Name',
  'job.application.content.email': 'Email',
  'job.application.content.phone': 'Phone',
  'job.application.content.currentCompany': 'Current Company',
  'job.application.content.submitSuccess': 'Thank You for Submitting Your Application!'
};
