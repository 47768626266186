import React from 'react';

import { withStyles } from 'src/app/helpers/styles';

interface MyProps {
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

@withStyles(require('./PopupContainer.scss'))
export class PopupContainer extends React.Component<MyProps> {
  render(): JSX.Element {
    const { children, onClick } = this.props;
    return (
      <div className="position-fixed top-0 bottom-0 left-0 right-0 popup-container" onClick={onClick}>
        {children}
      </div>
    );
  }
}
