import classnames from 'classnames';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { AdditionalQuestions } from 'src/app/models/job';
import { withStyles } from 'src/app/helpers/styles';
import { PopupContainer } from '../PopupShare/PopupContainer';
import { PopupError } from '../PopupError/PopupError';

interface JobApplicationFormProps {
    jobId: string;
    securityToken: string;
    additionalQuestions: AdditionalQuestions[];
    apiUrl: string;
    captchaKey: string;
    showSuccessPopup: () => void;
}

type JobApplicationData = {
    jobId: string;
    resume: string;
    firstName: string;
    lastName: string;
    gender: string;
    email: string;
    phone: string;
    securityToken: string;
    answers?: string[];
    questions?: string[];
};

enum GENDER_TYPE {
  MALE = 'MALE',
  FEMALE = 'FEMALE'
}

const defaultErrorMessage = 'Unknown Error';

const MyJobApplicationForm = (props: JobApplicationFormProps) => {
  const { register, handleSubmit, setError, errors } = useForm<JobApplicationData>({ mode: 'onChange' });
  const [fileName, setFileName] = useState('Attach Resume/CV');
  //const [captchaToken] = useState('');
  const [isErrorSubmit, setIsErrorSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [gender, setGender] = useState(GENDER_TYPE.MALE);
  const [securityToken, setSecurityToken] = useState(props.securityToken);
  const inputFileRef = React.useRef<HTMLInputElement>();

  // const onCaptchaChange = (token: string | null) => {
  //   setCaptchaToken(token || '');
  // };

  const onSubmit = (data: JobApplicationData) => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      const apiUrl = props.apiUrl;
      const bodyFormData = new FormData();
      bodyFormData.append('resume', data.resume[0]);
      bodyFormData.append('jobId', props.jobId);
      bodyFormData.append('firstName', data.firstName);
      bodyFormData.append('lastName', data.lastName);
      bodyFormData.append('gender', gender);
      bodyFormData.append('email', data.email);
      bodyFormData.append('phone', '+62' + data.phone);
      //bodyFormData.append('g-recaptcha-response', captchaToken);
      props.additionalQuestions.forEach((question) => {
        return bodyFormData.append('questions[]', question.id);
      });
      data.answers && data.answers.forEach((answer) => {
        return bodyFormData.append('answers[]', answer);
      });
      bodyFormData.append('securityToken', securityToken);
      axios({
        method: 'post',
        url: `${apiUrl}/jobs/submit_application`,
        data: bodyFormData,
        headers: {
          'content-type': undefined
        }
      })
        .then(function (result) {
          if (!result.data.error) {
            setIsSubmitting(false);
            setIsErrorSubmit(false);
            props.showSuccessPopup();
          } else {
            setIsSubmitting(false);
            setErrorMessage(result.data.message);
            setIsErrorSubmit(true);
            setSecurityToken(result.data.securityToken || props.securityToken);
          }
        })
        .catch(function () {
          setIsErrorSubmit(true);
          setErrorMessage(defaultErrorMessage);
        });
    }
  };

  const onFileChange = (event: any) => {
    setFileName(event.target.files[0].name);
    const fileSize = event.target.files[0].size;
    if (fileSize > 15000000) {
      setError('resume', {
        type: 'manual',
        message: 'PDF file, no bigger than 15mb'
      });
    }
  };

  const generateAdditionalQuestions = () => {
    return props.additionalQuestions.map((question, i) => {
      return (
        <div key={i} className="d-flex flw-wrap my-24">
          <div className="job-application-form__input-title">
            <label htmlFor="name">{question.title}</label>{question.mandatory && (<label className="fg-red">*</label>)}
          </div>
          <div className="job-application-form__input-field-container">
            <input
              className={classnames('job-application-form__input-field py-6 px-10 w-100', errors.answers && errors.answers[i] ? 'job-application-form__invalid-field' : 'job-application-form__valid-field')}
              type="text"
              name={`answers[${i}]`}
              ref={register({ required: question.mandatory })}
            />
            {errors.answers && errors.answers[i] && (
              <div className="p-5 job-application-form__error">Please input your answer</div>
            )}
          </div>
        </div>
      );
    });
  };

  const renderErrorPopup = (): JSX.Element | null => {
    if (isErrorSubmit && errorMessage === defaultErrorMessage) {
      return (
        <PopupContainer>
          <PopupError />
        </PopupContainer>
      );
    }

    return null;
  };

  return (
    <>
      {renderErrorPopup()}
      <form onSubmit={handleSubmit(onSubmit)} className="fs-18 job-application-form__container">
        <div className="d-flex flw-wrap my-24">
          <div className="job-application-form__input-title">
            <label htmlFor="name">Resume/CV</label><label className="fg-red">*</label>
          </div>
          <div className="job-application-form__input-field-container">
            <input
              type="file"
              id="resume"
              name="resume"
              ref={(elm: HTMLInputElement) => {
                register(elm, {
                  required: 'Please upload your Resume/CV'
                });
                inputFileRef.current = elm;
              }}
              hidden={true}
              onChange={onFileChange}
              accept="application/pdf"
            />
            <div className="d-flex flw-wrap">
              <div className={classnames('job-application-form__button py-6 d-flex', errors.resume ? 'job-application-form__attach-invalid' : 'job-application-form__attach-valid')} onClick={() => inputFileRef.current?.click()}>
                <div className="job-application-form__cv-title px-20 mx-20">
                  {fileName}
                </div>
              </div>
              {errors.resume && (
                <div className="p-5 p@md-10 job-application-form__error">{errors.resume.message}</div>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex flw-wrap my-24">
          <div className="job-application-form__input-title">
            <label htmlFor="firstName">First Name</label><label className="fg-red">*</label>
          </div>
          <div className="job-application-form__input-field-container">
            <input
              className={classnames('job-application-form__input-field py-6 px-10 w-100', errors.firstName ? 'job-application-form__invalid-field' : 'job-application-form__valid-field')}
              type="text"
              id="firstName"
              name="firstName"
              ref={register({ required: 'Please input your first name' })}
            />
            {errors.firstName && errors.firstName.type === 'required' && (
              <div className="p-5 job-application-form__error">{errors.firstName.message}</div>
            )}
          </div>
        </div>
        <div className="d-flex flw-wrap my-24">
          <div className="job-application-form__input-title">
            <label htmlFor="lastName">Last Name</label><label className="fg-red">*</label>
          </div>
          <div className="job-application-form__input-field-container">
            <input
              className={classnames('job-application-form__input-field py-6 px-10 w-100', errors.lastName ? 'job-application-form__invalid-field' : 'job-application-form__valid-field')}
              type="text"
              id="lastName"
              name="lastName"
              ref={register({ required: 'Please input your last name' })}
            />
            {errors.lastName && errors.lastName.type === 'required' && (
              <div className="p-5 job-application-form__error">{errors.lastName.message}</div>
            )}
          </div>
        </div>
        <div className="d-flex flw-wrap my-24">
          <div className="job-application-form__input-title">
            <label htmlFor="gender">Gender</label>
          </div>
          <div className="job-application-form__input-field-container d-flex">
            <label className="job-application-form__gender pr-40" onClick={() => setGender(GENDER_TYPE.MALE)}>
              <input className="flex-1" type="radio" value="MALE" name="gender" checked={gender === GENDER_TYPE.MALE} /> Male
            </label>
            <label className="job-application-form__gender" onClick={() => setGender(GENDER_TYPE.FEMALE)}>
              <input className="flex-1" type="radio" value="FEMALE" name="gender" checked={gender === GENDER_TYPE.FEMALE}/> Female
            </label>
          </div>
        </div>
        <div className="d-flex flw-wrap my-24">
          <div className="job-application-form__input-title">
            <label htmlFor="email">Email</label><label className="fg-red">*</label>
          </div>
          <div className="job-application-form__input-field-container">
            <input
              className={classnames('job-application-form__input-field py-6 px-10 w-100', errors.email ? 'job-application-form__invalid-field' : 'job-application-form__valid-field')}
              type="email"
              id="email"
              name="email"
              aria-invalid={errors.email ? 'true' : 'false'}
              ref={register({
                required: 'Please input a valid email address',
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Please input a valid email address'
                }
              })}
            />
            {errors.email && (
              <div className="p-5 job-application-form__error">{errors.email.message}</div>
            )}
          </div>
        </div>
        <div className="d-flex flw-wrap my-24">
          <div className="job-application-form__input-title">
            <label htmlFor="score">Phone</label><label className="fg-red">*</label>
          </div>
          <div className="job-application-form__input-field-container-relative">
            <input
              className={classnames('job-application-form__input-field-number py-6 px-40 w-100', errors.phone ? 'job-application-form__invalid-field' : 'job-application-form__valid-field')}
              type="number"
              id="phone"
              name="phone"
              ref={register({ required: 'Please input a valid phone numbers' })}
            />
            <span className="job-application-form__unit">+62</span>
            {errors.phone && errors.phone.type === 'required' && (
              <div className="p-5 job-application-form__error">{errors.phone.message}</div>
            )}
          </div>
        </div>
        {generateAdditionalQuestions()}
        {/* <Controller
          control={control}
          name="recaptcha"
          render={() =>
            <ReCaptcha
              sitekey={props.captchaKey}
              onTokenChange={onCaptchaChange}
            />
          }
        /> */}
        <button type="submit"
          className={classnames('job-application-form__button py-2 mt-20 px-20', (!isSubmitting) ? 'job-application-form__submit' : 'job-application-form__submit-disabled')}>
            Submit Application
        </button>
        {isErrorSubmit && (
          <div className="p-5 job-application-form__error">{errorMessage}</div>
        )}
      </form>
    </>
  );
};

export const JobApplicationForm = withStyles({
  base: [require('./JobApplicationForm.base.scss')],
  desktop: [require('./JobApplicationForm.desktop.scss')]
})(MyJobApplicationForm);
