import React from 'react';
import { FormattedMessage, WrappedComponentProps as InjectedIntlProps } from 'react-intl';

import { withIntl } from 'src/app/helpers/locale';
import { withStyles } from 'src/app/helpers/styles';

import { JobHeaderItem } from 'src/app/models/job';
import { PopupContainer } from 'src/app/components/PopupShare/PopupContainer';
import { PopupShare } from 'src/app/components/PopupShare/PopupShare';
import { withConfig } from 'src/app/helpers/config';
import { InjectedConfigProps } from 'src/app/models/config';
import { defaultImage } from 'src/app/components/CategoryCard/CategoryCard';

type MyInjectedProps = InjectedIntlProps & InjectedConfigProps;

interface MyOwnProps {
  job: JobHeaderItem;
  onApplyButtonClick: () => void;
  showActionButton: boolean;
  imgHeader?: string;
}

type MyProps = MyOwnProps & MyInjectedProps;

interface MyState {
  isSharePopupActive: boolean;
  showFixedHeader: boolean;
}

@withConfig()
@withIntl()
@withStyles({
  base: [require('./JobDescriptionHeader.base.scss')],
  desktop: [require('./JobDescriptionHeader.desktop.scss')]
})
class MyClassJobDescriptionHeader extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);

    this.state = {
      isSharePopupActive: false,
      showFixedHeader: false
    };
    this.toggleSharePopup = this.toggleSharePopup.bind(this);
  }

  render(): JSX.Element {
    const { job, showActionButton, onApplyButtonClick, imgHeader } = this.props;
    const img = imgHeader || defaultImage.default;

    const style: React.CSSProperties = {
      backgroundImage: `url(${img})`
    };

    return (
      <>
        <div className="job-description-header bg-black ta-left mx@md-0 position-relative" style={style}>
          <div className="bg-black opacity-50 job-description-header__overlay"></div>
          <div className="job-description-header__container d-block d@md-flex jc-between flw-wrap container container-fluid">
            <div>
              <div className="fs-24 fs@md-32 mb-24">
                {job.title}
              </div>
              <div className="fs-16 fs@md-20">
                {job.company}
              </div>
              <div className="fs-16 mb-20 job-description-header__location">
                {job.location}
              </div>
            </div>
            {showActionButton && (
              <div className="d-flex ai-center fs-16">
                <div className="job-description-header__button job-description-header__button--share py-2 d-flex fg-white" onClick={this.toggleSharePopup}>
                  <FormattedMessage id="job.description.header.share" />
                </div>
                <div className="job-description-header__button job-description-header__button--apply py-2 ml-8 d-flex fg-white" onClick={onApplyButtonClick}>
                  <FormattedMessage id="job.description.header.apply" />
                </div>
              </div>
            )}
          </div>
        </div>
        {this.state.isSharePopupActive && this.renderedSharePopup}
      </>
    );
  }

  private get renderedSharePopup(): JSX.Element {
    const { job, config: { app: { mainSitesUrl } } } = this.props;
    const shareUrl = `${mainSitesUrl}?id=${job.id}`;

    return (
      <PopupContainer onClick={this.toggleSharePopup}>
        <PopupShare
          shareUrl={shareUrl}
          jobTitle={job.title}
          jobCompany={job.company}
          jobLocation={job.location}
          onCloseIconClick={this.toggleSharePopup}
          onHeader={true}
        />
      </PopupContainer>
    );
  }

  private toggleSharePopup(): void {
    this.setState({
      isSharePopupActive: !this.state.isSharePopupActive
    });
  }
}

export const JobDescriptionHeader = MyClassJobDescriptionHeader as React.ComponentClass<MyOwnProps>;
