import classnames from 'classnames';
import React from 'react';

import { withStyles } from 'src/app/helpers/styles';

interface MyProps extends React.HTMLProps<HTMLSpanElement> {
  className?: string;
}

@withStyles(require('./IconFrame.scss'))
export class IconFrame extends React.Component<MyProps> {
  render(): JSX.Element {
    const { className, children, ...rest } = this.props;
    return (
      <span {...rest} className={classnames('icon-frame d-inline-flex ai-center ta-center jc-center va-middle', className)}>
        {children}
      </span>
    );
  }
}
