export class SearchResultItem {
  static mapFromSearchResultItemQueryResult(itemQuery: SearchResultItemQueryResult): SearchResultItem[] {
    const ret: SearchResultItem[] = [];

    for (const key in itemQuery) {
      const value = itemQuery[key];
      ret.push({
        logo: value.image,
        companyName: value.name,
        url: value.url,
        jobItems: value.job.map((jobValue) => SearchResultJob.mapFromSearchJobQueryResult(jobValue))
      });
    }

    return ret;
  }

  logo: string;
  companyName: string;
  url: string;
  jobItems: SearchResultJob[];
}

export class SearchResultJob {
  static mapFromSearchJobQueryResult(itemQuery: SearchJobQueryResult): SearchResultJob {
    return {
      id: itemQuery.id,
      name: itemQuery.name,
      location: itemQuery.location
    };
  }

  id: string;
  name: string;
  location: string;
}

export interface SearchResultQueryResult {
  error: boolean;
  data: {
    totalPages: number;
    numberElement: number;
    numberElementShows: number;
    last: boolean;
    searchResult: SearchResultItemQueryResult;
  };
}

export interface SearchResultItemQueryResult {
  [name: string]: {
    id: string;
    name: string;
    image: string;
    url: string;
    job: SearchJobQueryResult[];
  };
}

export interface SearchJobQueryResult {
  id: string;
  name: string;
  location: string;
}
