import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import { RouteComponentProps } from 'react-router-dom';

import { JobDescriptionHeader } from 'src/app/components/JobDescription/JobDescriptionHeader';
import { JobDescriptionContent } from 'src/app/components/JobDescription/JobDescriptionContent';
import { withQuery } from 'src/app/helpers/query';
import { QueryInjectedProps } from 'src/app/models/query';
import { JobDetailsQueryResult, JobHeaderItem, JobContentSectionItem, MoreJobsParams, AdditionalQuestions } from 'src/app/models/job';
import { Loader } from 'src/app/components/Loader/Loader';
import { JobApplicationContent } from 'src/app/components/JobApplication/JobApplicationContent';
import { JOB_APPLICATION, JOB_DESCRIPTION, PARAMS } from 'src/app/constants/route';
import { CategoryItemQueryResult } from 'src/app/models/category';
import { withConfig } from 'src/app/helpers/config';
import { InjectedConfigProps } from 'src/app/models/config';
import { PopupContainer } from 'src/app/components/PopupShare/PopupContainer';
import { PopupShare } from 'src/app/components/PopupShare/PopupShare';
import { withStyles } from 'src/app/helpers/styles';
import { scrollTopIframe } from 'src/app/helpers/iframe';
import { MoreJobs } from 'src/app/containers/MoreJobs/MoreJobs';

interface MyInjectedProps extends InjectedConfigProps{
  jobDetailsData: QueryInjectedProps<JobDetailsQueryResult>;
  categoryListData: QueryInjectedProps<CategoryItemQueryResult>;
}

type MyOwnProps = RouteComponentProps<{ id: string; }>;

type MyProps = MyOwnProps & MyInjectedProps;

interface MyState {
  showDetail: boolean;
  isSharePopupActive: boolean;
}

@withConfig()
@withQuery('/jobs/detail', {
  name: 'jobDetailsData'
})
@withQuery('/category/list', {
  name: 'categoryListData'
})
@withStyles(require('./JobDescription.scss'))
class MyJobDescription extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);

    this.state = {
      showDetail: !this.props.location.pathname.includes(JOB_APPLICATION.replace(PARAMS.ID, '')),
      isSharePopupActive: false
    };

    props.jobDetailsData.setParam({
      queryString: props.match.params.id
    });
    this.toggleShowDetail = this.toggleShowDetail.bind(this);
    this.toggleSharePopup = this.toggleSharePopup.bind(this);
  }

  componentDidUpdate(prevProps: MyProps): void {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.jobDetailsData.fetch({
        queryString: this.props.match.params.id
      });
    }
  }

  render(): JSX.Element {
    const { jobDetailsData: { resultData, isLoading }, categoryListData } = this.props;

    if ((isLoading && !resultData) || categoryListData.isLoading) {
      return <Loader />;
    } else if (resultData) {
      const jobHeaderItem = JobHeaderItem.mapFromJobDetailsQueryResult(resultData);
      const imgHeader = categoryListData.resultData?.data.find((item) => item.name === jobHeaderItem.category)?.image;
      
      return (
        <>
          <Helmet>
            <title>Job Description</title>
          </Helmet>
          <div className="bg-gray-lighter">
            <div>
              <JobDescriptionHeader
                job={jobHeaderItem}
                onApplyButtonClick={this.toggleShowDetail}
                showActionButton={this.state.showDetail}
                imgHeader={imgHeader} />
              <div className="container container-fluid">
                <div className="d-block d@md-flex jc-between mt-30">
                  <div className="d-block mr@md-30 flex-1">
                    {this.renderContent()}
                    {this.state.showDetail && this.renderActionButton()}
                  </div>
                  {/* {this.renderMoreJobs()} */}
                </div>
              </div>
            </div>
            {this.state.isSharePopupActive && this.renderSharePopup()}
          </div>
        </>
      );
    } else {
      return <div>ERROR</div>;
    }
  }

  renderContent(): JSX.Element {
    const { jobDetailsData: { resultData } } = this.props;
    if (resultData && this.state.showDetail) {
      const jobContentSectionItems = JobContentSectionItem.mapFromJobDetailsQueryResult(resultData);

      return (
          <JobDescriptionContent
            jobContentSections={jobContentSectionItems}
          />
      );
    } else if (resultData && !this.state.showDetail) {
      return (
        <JobApplicationContent
          jobId={resultData.data?.id || ''}
          securityToken={resultData.securityToken}
          questions={AdditionalQuestions.mapFromJobDetailsQueryResult(resultData)}
          onSuccessSubmit={this.toggleShowDetail}
        />
      );
    } else {
      return (<></>);
    }
  }

  renderMoreJobs(): JSX.Element {
    const { jobDetailsData: { resultData } } = this.props;
    if (resultData && this.state.showDetail) {
      const moreJobsparam = MoreJobsParams.mapFromJobDetailsQueryResult(resultData);

      return (
        <div className="job-description-content__right fs-20 mb-30 mb@md-0">
          <MoreJobs params={moreJobsparam} />
        </div>
      );
    } else {
      return (<></>);
    }
  }

  renderActionButton(): JSX.Element {
    return (
      <div className="d-flex fs-16 pb-60">
        <div className="job-description__button job-description__button--share py-2 d-flex" onClick={this.toggleSharePopup}>
          <FormattedMessage id="job.description.header.share" />
        </div>
        <div className="job-description__button job-description__button--apply py-2 ml-8 d-flex fg-white" onClick={this.toggleShowDetail}>
          <FormattedMessage id="job.description.header.apply" />
        </div>
      </div>
    );
  }

  renderSharePopup(): JSX.Element | null {
    const { jobDetailsData: { resultData }, config: { app: { mainSitesUrl } } } = this.props;
    if (!resultData) {
      return null;
    }

    const jobHeaderItem = JobHeaderItem.mapFromJobDetailsQueryResult(resultData);
    const shareUrl = `${mainSitesUrl}?id=${jobHeaderItem.id}`;

    return (
      <PopupContainer onClick={this.toggleSharePopup}>
        <PopupShare
          shareUrl={shareUrl}
          jobTitle={jobHeaderItem.title}
          jobCompany={jobHeaderItem.company}
          jobLocation={jobHeaderItem.location}
          onCloseIconClick={this.toggleSharePopup}
          onHeader={false}
        />
      </PopupContainer>
    );
  }

  toggleShowDetail(): void {
    const { jobDetailsData: { resultData } } = this.props;
    const isShowDetail = this.state.showDetail;
    this.setState({
      showDetail: !isShowDetail
    });
    if (!isShowDetail) {
      this.props.history.push(JOB_DESCRIPTION.replace(PARAMS.ID, resultData?.data?.id || ''));
      scrollTopIframe();
    } else {
      this.props.history.push(JOB_APPLICATION.replace(PARAMS.ID, resultData?.data?.id || ''));
      scrollTopIframe();
    }
  }

  private toggleSharePopup(): void {
    this.setState({
      isSharePopupActive: !this.state.isSharePopupActive
    });
  }
}

export const JobDescription = MyJobDescription as React.ComponentClass<MyOwnProps>;
