import React from 'react';

import { CategoryCard } from 'src/app/components/CategoryCard/CategoryCard';
import { GhostElement } from 'src/app/components/GhostElement/GhostElement';
import { withStyles } from 'src/app/helpers/styles';
import { CategoryItem } from 'src/app/models/category';

const noJobImage = require('src/images/no_jobs_image.svg');

interface MyProps {
  categoryItems: CategoryItem[];
  openSearchCategory: (categoryName: string) => void;
}

@withStyles({
  base: [require('./HomeContent.base.scss')],
  desktop: [require('./HomeContent.desktop.scss')]
})
export class HomeContent extends React.Component<MyProps> {
  render(): JSX.Element {
    const { categoryItems } = this.props;
    let total = 0;
    categoryItems.forEach((value) => total += value.totalJobs);
    if (total == 0) {
      return (
        <>
          <div className="home-content__images">
            <div className="home-content__images__text1">SORRY!</div>
          </div>
          <div className="home-content__images">
            <div className="home-content__images__text2">No jobs available,</div>
            <div className="home-content__images__text3">please check on us later</div>
          </div>
          <div className="home-content__images">
            <img src={noJobImage.default} className="home-content__images__image"/>
          </div>
        </>
      );
    } else {
      return (
        <div className="d-flex flw-wrap home-content__container">
          {this.renderCategoryCardList()}
          <GhostElement className="flex-1 home-content__item" count={3}/>
        </div>
      );
    }
  }

  renderCategoryCardList(): JSX.Element[] {
    const { categoryItems, openSearchCategory } = this.props;
    return categoryItems.map((categoryItem, i) => (
      <div key={i} className="flex-1 home-content__item">
        <CategoryCard
          categoryItem={categoryItem}
          openSearchCategory={openSearchCategory}
        />
      </div>
    ));
  }
}
