export interface JobDetails {
  id: string;
  title: string;
  company: string;
  location: string;
  description: Section[];
  moreJob: JobItem[];
  questions: AdditionalQuestions[];
  securityToken: string;
}

export interface Section {
  title: string;
  content: string;
}

export interface JobItem {
  title: string;
  company: string;
  location: string;
}

export class AdditionalQuestions {
  static mapFromJobDetailsQueryResult(itemQuery: JobDetailsQueryResult): AdditionalQuestions[] {
    if (itemQuery.data?.questions) {
      return itemQuery.data?.questions.map((question) => {
        return {
          id: question.id || '',
          title: question.title || '',
          mandatory: question.mandatory,
          sequence: question.sequence || 0,
          config: {
            id: question.config.id || '',
            type: question.config.type || '',
            minLength: question.config.minLength,
            maxLength: question.config.maxLength,
            originalAnswer: question.config.originalAnswer
          }
        };
      });
    } else {
      return [];
    }
  }

  id: string;
  title: string;
  mandatory: boolean;
  sequence: number;
  config: {
    id: string;
    type: string;
    minLength?: number;
    maxLength?: number;
    originalAnswer?: string;
  };
}

export class JobHeaderItem {
  static mapFromJobDetailsQueryResult(itemQuery: JobDetailsQueryResult): JobHeaderItem {
    return {
      id: (itemQuery.data?.id) || '',
      title: (itemQuery.data?.jobTitle?.name) || '',
      company: (itemQuery.data?.organization?.name) || '',
      location: (itemQuery.data?.location?.city?.name) || '',
      category: (itemQuery.data?.jobDetail?.jobFunction) || ''
    };
  }

  id: string;
  title: string;
  company: string;
  location: string;
  category: string;
}

export interface AdditionalQuestionsQueryResults {
  id: string;
  title: string;
  mandatory: boolean;
  sequence: number;
  config: {
    id: string;
    type: string;
    minLength?: number;
    maxLength?: number;
    originalAnswer?: string;
  };
}

export class JobContentSectionItem {
  static mapFromJobDetailsQueryResult(itemQuery: JobDetailsQueryResult): JobContentSectionItem[] {
    const jobContentSectionItems: JobContentSectionItem[] = [];

    if (itemQuery.data?.jobDetail?.description) {
      jobContentSectionItems.push({
        title: 'Descriptions',
        content: itemQuery.data.jobDetail.description
      });
    }

    if (itemQuery.data?.jobDetail?.requirement) {
      jobContentSectionItems.push({
        title: 'Requirements',
        content: itemQuery.data.jobDetail.requirement
      });
    }

    if (itemQuery.data?.jobDetail?.benefit) {
      jobContentSectionItems.push({
        title: 'Benefits',
        content: itemQuery.data.jobDetail.benefit
      });
    }

    return jobContentSectionItems;
  }

  title: string;
  content: string;
}

export interface JobDetailsQueryResult {
  error: boolean;
  securityToken: string;
  data?: {
    id?: string;
    titleDescription?: string;
    jobTitle?: {
      id?: string;
      name?: string;
    };
    jobDetail?: {
      description?: string;
      requirement?: string;
      benefit?: string;
      jobFunction?: string;
    };
    organization?: {
      id?: string;
      name?: string;
    };
    location?: {
      city?: {
        name?: string;
        state?: {
          name?: string;
          country?: {
            name?: string;
          };
        };
      };
    };
    questions?: AdditionalQuestionsQueryResults[];
  };
}

export interface MoreJobsQueryResult {
  error: boolean;
  data?: MoreJobsQueryResultItem[];
}

export interface MoreJobsQueryResultItem {
  id?: string;
  title?: string;
  category?: string;
  organization?: string;
  location?: string;
}

export class MoreJobsParams {
  static mapFromJobDetailsQueryResult(itemQuery: JobDetailsQueryResult): MoreJobsParams {
    return {
      organizationId: itemQuery.data?.organization?.id,
      jobsId: itemQuery.data?.id,
      title: itemQuery.data?.jobTitle?.name,
      category: itemQuery.data?.jobDetail?.jobFunction
    };
  }

  title?: string;
  organizationId?: string;
  category?: string;
  jobsId?: string;
}
