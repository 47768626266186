// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/Gotham/Gotham-Book.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/Gotham/GothamMedium.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../fonts/Gotham/Gotham-Black.otf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../fonts/OpenSans/OpenSans-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../fonts/OpenSans/OpenSans-Semibold.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, "@font-face{font-family:Gotham;font-display:swap;font-style:normal;font-weight:400;src:local(\"Gotham Book\"),local(\"Gotham-Book\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}@font-face{font-family:Gotham;font-display:swap;font-style:normal;font-weight:500;src:local(\"Gotham Medium\"),local(\"Gotham-Medium\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}@font-face{font-family:Gotham;font-display:swap;font-style:normal;font-weight:700;src:local(\"Gotham Black\"),local(\"Gotham-Black\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}@font-face{font-family:OpenSans;font-display:swap;font-style:normal;font-weight:400;src:local(\"OpenSans-Regular\"),local(\"OpenSans-Regular\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}@font-face{font-family:OpenSans;font-display:swap;font-style:normal;font-weight:500;src:local(\"OpenSans-Semibold\"),local(\"OpenSans Semibold\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ")}", ""]);
// Exports
module.exports = exports;
