import React from 'react';
import { WrappedComponentProps as InjectedIntlProps } from 'react-intl';

import { withIntl } from 'src/app/helpers/locale';
import { withStyles } from 'src/app/helpers/styles';
import { JobContentSectionItem } from 'src/app/models/job';

type MyInjectedProps = InjectedIntlProps;

interface MyOwnProps {
  jobContentSections: JobContentSectionItem[];
}

type MyProps = MyOwnProps & MyInjectedProps;

@withIntl()
@withStyles({
  base: [require('./JobDescriptionContent.base.scss')],
  desktop: [require('./JobDescriptionContent.desktop.scss')]
})
export class MyClassJobDescriptionContent extends React.Component<MyProps> {
  render(): JSX.Element {
    return (
      <div className="mr@md-30 flex-1 mb-30 mb@mr-0">
        {this.renderContent()}
      </div>
    );
  }

  renderContent(): JSX.Element[] {
    const { jobContentSections } = this.props;
    return jobContentSections.map((sectionItem, i) => (
      <div key={i}>
        <div className="fs-20 mb-16 job-description-content__content-header">
          {sectionItem.title}
        </div>
        <div className="fs-18 mb-32 job-description-content__content-text" dangerouslySetInnerHTML={{ __html: sectionItem.content }} />
      </div>
    ));
  }
}

export const JobDescriptionContent = MyClassJobDescriptionContent as React.ComponentClass<MyOwnProps>;
