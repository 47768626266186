import classnames from 'classnames';
import React from 'react';

import { withStyles } from 'src/app/helpers/styles';

interface MyProps {
  className?: string;
}

@withStyles(require('./Loader.scss'))
export class Loader extends React.Component<MyProps> {
  render(): JSX.Element {
    const { className } = this.props;

    return (
      <div className={classnames('d-flex ai-center jc-center py-20', className)}>
        <div className="loader" />
      </div>
    );
  }
}
