import classnames from 'classnames';
import React from 'react';
import qs from 'qs';

import { SHARE_TYPE } from 'src/app/constants/share';
import { ButtonIcon } from 'src/app/components/Button/ButtonIcon';
import { IconClose, IconCloseMobile, IconFacebook, IconMail, IconTwitter, IconWhatsApp, IconLinkedIn } from 'src/app/components/Icon/Icon';
import { withInjectedStyles } from 'src/app/helpers/styles';
import { InjectedStylesProps } from 'src/app/models/styles';
import { isMobile } from 'src/app/helpers/device';

interface MyOwnProps {
  shareUrl: string;
  jobTitle: string;
  jobCompany: string;
  jobLocation: string;
  onHeader?: boolean;
  onCloseIconClick: () => void;
}

type MyInjectedProps = InjectedStylesProps;

type MyProps = MyOwnProps & MyInjectedProps;

@withInjectedStyles(require('./PopupShare.scss'))
class MyClassPopupShare extends React.Component<MyProps> {
  constructor(props: MyProps) {
    super(props);

    this.shareJob = this.shareJob.bind(this);
  }

  render(): JSX.Element {
    const { jobTitle, jobLocation, jobCompany, onHeader } = this.props;

    const classNameIcon = 'popup-share__button-icon';

    return (
      <div onClick={this.onPopupClick} className={classnames('px-20 py-16 pb-34 bg-white radius-small position-absolute popup-share ta-center', onHeader ? 'popup-share__top' : 'popup-share__bottom')}>
        <ButtonIcon
          className="mt-10 mx-4 border-button p-8 p@md-10"
          onClick={this.props.onCloseIconClick}
          icon={isMobile() ? IconCloseMobile : IconClose}
          modifier="clear"
        />
        <div className="mt-24 fg-black ta-center fs-24">
          Share this page
        </div>
        <div className="mt-24 fg-white d-flex jc-around fs-34">
          <ButtonIcon
            onClick={this.shareJob(SHARE_TYPE.WHATSAPP)}
            icon={IconWhatsApp}
            modifier="clear"
            isCircular={false}
            classNameIcon={classNameIcon}
          />
          <ButtonIcon
            onClick={this.shareJob(SHARE_TYPE.MAIL)}
            icon={IconMail}
            modifier="clear"
            isCircular={false}
            classNameIcon={classNameIcon}
          />
          <ButtonIcon
            onClick={this.shareJob(SHARE_TYPE.LINKEDIN)}
            icon={IconLinkedIn}
            modifier="clear"
            isCircular={false}
            classNameIcon={classNameIcon}
          />
          <ButtonIcon
            onClick={this.shareJob(SHARE_TYPE.TWITTER)}
            icon={IconTwitter}
            modifier="clear"
            isCircular={false}
            classNameIcon={classNameIcon}
          />
          <ButtonIcon
            onClick={this.shareJob(SHARE_TYPE.FACEBOOK)}
            icon={IconFacebook}
            modifier="clear"
            isCircular={false}
            classNameIcon={classNameIcon}
          />
        </div>
        <hr/>
        <div className="mt-24 fg-black ta-center">
          <div className="fs-20">{jobTitle}</div>
          <div className="fs-16 fw-300">{`${jobCompany}, ${jobLocation}`}</div>
        </div>
      </div>
    );
  }

  private onPopupClick(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
  }

  private shareJob(type: SHARE_TYPE): () => void {
    const { shareUrl, jobTitle, jobCompany, jobLocation } = this.props;

    return () => {
      switch (type) {
        case SHARE_TYPE.TWITTER:
          const twitterParams = { url: shareUrl, text: `${jobTitle}, ${jobCompany} - ${jobLocation}. ` };
          window.open('https://twitter.com/intent/tweet?' + qs.stringify(twitterParams), '_blank', 'noopener');
          break;
        case SHARE_TYPE.FACEBOOK:
          if (isMobile()) {
            window.open('https://m.facebook.com/sharer.php?u=' + encodeURIComponent(shareUrl), '_blank', 'noopener');
          } else {
            window.open('https://www.facebook.com/sharer.php?u=' + encodeURIComponent(shareUrl), '_blank', 'noopener');
          }
          break;
        case SHARE_TYPE.MAIL:
          const emailBody = `Job Vacancy: ${jobTitle}%0D%0ACompany: ${jobCompany}%0D%0ALocation: ${jobLocation}%0D%0AJob Page: ${encodeURIComponent(shareUrl)}`;
          window.open('mailto:?subject=' + `Job Vacancy: ${jobTitle}, ${jobCompany}` + '&body=' + emailBody, '_self', 'noopener');
          break;
        case SHARE_TYPE.WHATSAPP:
          if (isMobile()) {
            window.open('https://api.whatsapp.com/send?text=' + `${jobTitle}, ${jobCompany} - ${jobLocation}. ${encodeURIComponent(shareUrl)}`, '_blank', 'noopener');
          } else {
            window.open('https://web.whatsapp.com/send?text=' + `${jobTitle}, ${jobCompany} - ${jobLocation}. ${encodeURIComponent(shareUrl)}`, '_blank', 'noopener');
          }
          break;
        case SHARE_TYPE.LINKEDIN:
          window.open('https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(shareUrl));
      }
    };
  }
}

export const PopupShare = MyClassPopupShare as React.ComponentClass<MyOwnProps>;
