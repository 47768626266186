import React from 'react';

import { Config, InjectedConfigProps } from 'src/app/models/config';

export const ConfigContext = React.createContext<Config>({
  app: { apiUrl: '', mainSitesUrl: '', captchaKey: '' },
  platform: { buildMode: '', buildNumber: '', csrUrl: '', environment: '', publicUrl: '', ssrUrl: '' }
});

export function withConfig(): <C>(WrappedComponent: C) => C {
  function wrapperComponent<C>(WrappedComponent: C): C;
  function wrapperComponent<P extends InjectedConfigProps>(WrappedComponent: React.ComponentType<P>): React.ComponentClass<Pick<P, Exclude<keyof P, keyof InjectedConfigProps>>> {
    return class MyClassWithConfig extends React.Component<P> {
      render(): JSX.Element {
        return (
          <ConfigContext.Consumer>
            {(config) => <WrappedComponent {...this.props} config={config} />}
          </ConfigContext.Consumer>
        );
      }
    };
  }

  return wrapperComponent;
}
