import classnames from 'classnames';
import React from 'react';

import { withStyles } from 'src/app/helpers/styles';

interface MyProps {
  modifier: '16-9' | '4-3' | '3-4' | '1-1' | 'none';
  className?: string;
}

@withStyles(require('./ResponsiveContainer.scss'))
export class ResponsiveContainer extends React.Component<MyProps> {
  render(): JSX.Element {
    const { children, className, modifier } = this.props;
    return (
      <div className={classnames('position-relative overflow-hidden responsive-container__ratio', 'responsive-container__ratio--' + modifier, className)}>
        {children}
      </div>
    );
  }
}
