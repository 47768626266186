import React from 'react';
import { WrappedComponentProps as InjectedIntlProps, injectIntl } from 'react-intl';

export function withIntl(): <C>(WrappedComponent: C) => C {
  function wrapperComponent<C>(WrappedComponent: C): C;
  function wrapperComponent<P extends InjectedIntlProps>(WrappedComponent: React.ComponentType<P>): React.ComponentType<Pick<P, Exclude<keyof P, keyof InjectedIntlProps>>> {
    return injectIntl(WrappedComponent);
  }

  return wrapperComponent;
}
